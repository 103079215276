import $ from "jquery";

export class SimpleAjax {
  constructor(baseURL) {
    this.baseURL = baseURL;
  }

  static interceptors = {
    request: [],
    response: [],
  };

  addRequestInterceptor(interceptor) {
    SimpleAjax.interceptors.request.push(interceptor);
  }

  addResponseInterceptor(interceptor) {
    SimpleAjax.interceptors.response.push(interceptor);
  }

  get(url, data) {
    return this.request("GET", url, data);
  }

  post(url, data) {
    return this.request("POST", url, data);
  }

  put(url, data) {
    return this.request("PUT", url, data);
  }

  delete(url, data) {
    return this.request("DELETE", url, data);
  }

  request(method, url, data) {
    return new Promise((resolve, reject) => {
      const requestURL = this.baseURL + url;

      // Apply request interceptors
      let requestData = { method, url: requestURL, data };
      for (let interceptor of SimpleAjax.interceptors.request) {
        requestData = interceptor(requestData);
      }

      $.ajax({
          method: requestData.method,
          url: requestData.url,
          data: requestData.data,
          success: (response) => {
            for (let interceptor of SimpleAjax.interceptors.response) {
              response = interceptor(response);
            }

            resolve(response);
          },
          error: (xhr, status, error) => {
            reject({ xhr, status, error });
          },
        });
      }
    );
  }
}
