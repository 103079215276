import { createAction } from "redux-actions";
import { sheepProductId } from "../../constants";

export const types = {
    getPoductsInProgress:"getPoductsInProgress",
    getPoductsSuccess:"getPoductsSuccess",
    getPoductsFail:"getPoductsFail",

    getProductByIdInProgress:"getProductByIdInProgress",
    getProductByIdSuccess:"getProductByIdSuccess",

    getSheepDataInProgress:"getSheepDataInProgress",
    getSheepDataSuccess:"getSheepDataSuccess",

    createPoductInProgress:"createPoductInProgress",
    createPoductSuccess:"createPoductSuccess",
    createPoductFail:"createPoducsFail"
}

const getPoductsInProgress = createAction(types.getPoductsInProgress)
const getPoductsSuccess = createAction(types.getPoductsSuccess)
const getPoductsFail = createAction(types.getPoductsFail)

const getProductByIdInProgress = createAction(types.getProductByIdInProgress)
const getProductByIdSuccess = createAction(types.getProductByIdSuccess)

const getSheepDataInProgress = createAction(types.getSheepDataInProgress)
const getSheepDataSuccess = createAction(types.getSheepDataSuccess)

const createPoductInProgress = createAction(types.createPoductInProgress)
const createPoductSuccess = createAction(types.createPoductSuccess)
const createPoductFail = createAction(types.createPoductFail)

const loadProducts = (query) => {
    return (dispatch, getState,Api)=>{
        dispatch(getPoductsInProgress())

        return Api.PRODUCTS.get(query)
        .then(res=>{
            dispatch(getPoductsSuccess(res))
            return Promise.resolve()
        })
        .catch(err=>{
            dispatch(getPoductsFail(err))
            return Promise.reject()
        })

        
    }
}

const getProductById = (id) => {
    return (dispatch, getState,Api)=>{
        dispatch(getProductByIdInProgress())

        return Api.PRODUCTS.getById(id)
        .then(res=>{
            dispatch(getProductByIdSuccess(res))
            return Promise.resolve(res)
        })
    }
}

const getSheepData = () => {
    return (dispatch, getState,Api)=>{
        dispatch(getSheepDataInProgress())
        dispatch(getProductById(sheepProductId)).then(res=>{
            dispatch(getSheepDataSuccess(res))
        })
    }
}

const loadAdminProducts = (query) => {
    return (dispatch, getState,Api)=>{
        dispatch(getPoductsInProgress("Admin"))
        dispatch(getSheepData())
        return Api.PRODUCTS.AdminGet(query)
        .then(res=>{
            dispatch(getPoductsSuccess(res))
            return Promise.resolve()
        })
        .catch(err=>{
            dispatch(getPoductsFail(err))
            return Promise.reject()
        })

        
    }
}

const newProduct = (data) => {
    return (dispatch, getState, Api)=>{
        dispatch(createPoductInProgress(data))
        console.warn(Api);
        return Api.PRODUCTS.new(data).then(res=>{
            dispatch(createPoductSuccess(res))
            console.warn(res);
        }).catch(err=>{
            console.error(err);
            dispatch(createPoductFail(err))
        })
    }
}

const deleteProduct = (id) => {
    return (dispatch, getState, Api)=>{
        return Api.PRODUCTS.delete(id).then(res=>{
            return Promise.resolve(res)
        }).catch(err=>{
            console.error(err);
            return Promise.reject(err)
        })
    }
}

const setStatus = (id,status) => {
    return (dispatch, getState, Api)=>{
        return Api.PRODUCTS.setStatus(id,status).then(res=>{
            return Promise.resolve(res)
        }).catch(err=>{
            console.error(err);
            return Promise.reject(err)
        })
    }

}

export default {
    loadProducts,
    loadAdminProducts,
    newProduct,
    setStatus,
    deleteProduct,
    getProductById
}