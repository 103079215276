import React from 'react'
import { AiOutlineLoading3Quarters } from 'react-icons/ai'
import './styles.scss'

const LoadingIcon = ({
    width = 24,
    height = 24,
    className = '',
    show = false,
}) => {

  return show ? 
    <AiOutlineLoading3Quarters style={{width, height}} className={`customSpin ${className}`}/>
  : null
}

export default LoadingIcon