import { createAction } from "redux-actions";

export const types = {
    saveOrderInProgress: 'saveOrderInProgress',
    saveOrderSuccess: 'saveOrderSuccess',
    saveOrderFailed: 'saveOrderFailed',
}

const saveOrderInProgress = createAction(types.saveOrderInProgress)
const saveOrderSuccess = createAction(types.saveOrderSuccess)
const saveOrderFailed = createAction(types.saveOrderFailed)

const getOrderByToken = () => {
    return (dispatch, getState, Api) => {
        return {
            action: (token) => Api.ORDERS.getOrderByToken(token)  
        } 
    }
}

const getOrderByPreferenceId = () => {
    return (dispatch, getState, Api) => {
        return {
            action: (preferenceId) => Api.ORDERS.getOrderByPreferenceId(preferenceId)  
        } 
    }
}

const saveOrder = (data, successCallback=()=>{}, failCallback=()=>{}) => {
    return (dispatch, getState, Api)=>{
        dispatch(saveOrderInProgress())
        return Api.ORDERS.new(data)
            .then((res)=>{
                dispatch(saveOrderSuccess(res.data))
                successCallback(res)
                return Promise.resolve(res);
            })
            .catch((err)=>{
                dispatch(saveOrderFailed(err))
                failCallback()
                return Promise.reject(err);
            })

    }
}

export default {
 saveOrder,
 getOrderByToken,
 getOrderByPreferenceId
}