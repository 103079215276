import { createAction } from "redux-actions";

export const types = {
    showpopupMessage:'showPopupMessage',
    closepopup: 'closePopup',
}

const showPopupMessage = createAction(types.showpopupMessage);
const removeMessage = createAction(types.closepopup);

const showPopup = (title, message) => {
    return (dispatch, getState, Api) => {
        dispatch(showPopupMessage({title,message}))
    }
}

const closePopup = () => {
    return (dispatch, getState, Api) => {
        dispatch(removeMessage())
    }
}

export const popupActions = {
    showPopup,
    closePopup
}
