import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import NewProducModal from '../../../../components/newProductModal'
import { Button } from 'reactstrap'
import EditProductItem from './editProductItem'
import EditProductModal from '../../../../components/editProductModal'
import ProducsActions from '../../../../store/products/actions'

const uncconnectedProductsTab = ({
  products,
  loadProducts
}) => {

  const [modalCreateProductOpen, setModalCreateProductOpen] = useState(false)
  const [productToEdit, setProductToEdit] = useState(null)

  useEffect(() => {
    loadProducts({
      category: 0,
      limit: 0,
      page: 0
    });
  }, []);
  
  const handleOpenModalCreateProduct = () => {
    setModalCreateProductOpen(true)
  }

  const handleCloseModalCreateProduct = () => {
    setModalCreateProductOpen(false)
  }

  const shouldOpenModalEditProduct = () => {
    return productToEdit != null
  }

  const handleCloseModalEditProduct = () => {
    setProductToEdit(null)
  }

  const handleSelectProductToEdit = (product) =>  {
    setProductToEdit(product)
  } 

  return (
    <div className='productsAdminTab_container'> 
      <EditProductModal product={productToEdit} open={shouldOpenModalEditProduct()} closeModal={handleCloseModalEditProduct}/>
      <NewProducModal open={modalCreateProductOpen} closeModal={handleCloseModalCreateProduct}/>
      <div className='productsAdminTab_products'>
        <Button onClick={handleOpenModalCreateProduct}>Crear producto</Button>
        <div className='productsAdminTab_products_list'>
          {products.map((product, index) => {
            return <EditProductItem
              product={product}
              onClickEdit={handleSelectProductToEdit}
            />
          })}          
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  products: state.products.list,
})

const mapDistpatchToProps = (dispatch) => ({
  loadProducts: (query) => {
      return dispatch(ProducsActions.loadProducts(query));
  },
});

const ProductsTab = connect(
mapStateToProps,
mapDistpatchToProps
)(uncconnectedProductsTab)

export default ProductsTab