import {handleActions} from "redux-actions";
import { types } from "./actions";

const initialState = {
    getProductsInProgress:false,
    getProductsSuccess:false,
    getProductsFail:false,
    list:[],
    sheepData: null,
    getProductByIdInProgress: false,
    getProductByIdSuccess: false,
    getSheepDataInProgress: false,
    getSheepDataSuccess: false,
}

const reducer = handleActions({
    [types.getSheepDataSuccess]: (state, action) => ({
        ...state,
        sheepData: action.payload.data,
    }),
    [types.getPoductsInProgress]:(state)=>({
        ...state,
        getProductsInProgress:true,
        getProductsSuccess:false,
        getProductsFail:false
    }),
    [types.getPoductsSuccess]:(state,action) => ({
        ...state,
        getProductsInProgress:false,
        getProductsSuccess:true,
        getProductsFail:false,
        totalElements: action.payload.data.totalElements,
        actualPage: action.payload.data.actualPage,
        totalPages: action.payload.data.totalPages,
        list: action.payload.data.list,
        categories: action.payload.data.categories
    }),
    [types.getPoductsFail]:(state)=>({
        ...state,
        getProductsInProgress:false,
        getProductsSuccess:false,
        getProductsFail:true,
        totalElements: 0,
        actualPage: 1,
        totalPages: 1,
        list: [],
        categories: []
    })
},initialState)

export default reducer