import { createAction } from "redux-actions";

export const types = {
    createCategoryInProgress: "createCategoryInProgress",
    createCategorySuccess: "createCategorySuccess",
    createCategoryFailed: "createCategoryFailed",

    getCategoriesInProgress: "getCategoriesInProgress",
    getCategoriesSuccess: "getCategoriesSuccess",
    getCategoriesFailed: "getCategoriesFailed",
}

const createCategoryInProgress = createAction(types.createCategoryInProgress)
const createCategorySuccess = createAction(types.createCategorySuccess)
const createCategoryFailed = createAction(types.createCategoryFailed)

const getCategoriesInProgress = createAction(types.getCategoriesInProgress)
const getCategoriesSuccess = createAction(types.getCategoriesSuccess)
const getCategoriesFailed = createAction(types.getCategoriesFailed)

const createCategory = (name, successCallback, failCallback) => {
    return (dispatch, getState, Api)=>{
        dispatch(createCategoryInProgress())
        return Api.CATEGORIES.new(name)
            .then((res)=>{
                dispatch(createCategorySuccess(res.data))
                if(successCallback){
                    successCallback(res)
                }
            })
            .catch((err)=>{
                dispatch(createCategoryFailed(err))
                if(failCallback){
                    failCallback(err)
                }
            })

    }
}

const getCategories = (successCallback, failCallback) =>{
    return (dispatch, getState, Api)=>{
        dispatch(getCategoriesInProgress())
        return Api.CATEGORIES.get()
        .then((res)=>{
            dispatch(getCategoriesSuccess(res))
            if(successCallback){
                successCallback(res)
            }
        })
        .catch((err)=>{
            dispatch(getCategoriesFailed(err))
            if(failCallback){
                failCallback(err)
            }
        })

    }
}

export const CategoriesActions ={
    createCategory,
    getCategories,
}