import React, {useEffect, useState} from 'react'
import { connect } from 'react-redux'
import sessionActions from '../../store/session/actions'
import productsActions from '../../store/products/actions'
import { NavItem, TabContent, TabPane, Button } from 'reactstrap'
import ProductsTab from './tabs/productsTab'

import './styles.scss'

const AdminScreen = ({
  logIn,
  getAdminProducts
}) => { 

  const [activeTab, setactiveTab] = useState('Products')

  const changeTab = (tab) => {
    setactiveTab(tab)
  }
   
  useEffect(() => {
    switch (activeTab) {
      case 'Orders':

        break;
      case 'Products':
        getAdminProducts({
          page: 0,
          limit: 100,
        })
        break;
      case 'Users':
        break;
      default:
        break;
    
    }
  }, [activeTab])
  

  return (
    <div className='admin_container'>
      <div className='admin_Nav_container' tabs>
        <div className='admin_Nav'>
        <NavItem
            className={`admin_Nav_item ${activeTab === 'Orders' ? 'admin_Nav_item_active' : ''}`}
            onClick={() =>changeTab('Orders')}
          >
            Pedidos
        </NavItem>
        <NavItem
            className={`admin_Nav_item ${activeTab === 'Products' ? 'admin_Nav_item_active' : ''}`}
            onClick={() =>changeTab('Products')}
          >
            Productos
        </NavItem>
        <NavItem
            className={`admin_Nav_item ${activeTab === 'Users' ? 'admin_Nav_item_active' : ''}`}
            active={activeTab ===  'Users'}
            onClick={() =>changeTab('Users')}
          >
            Usuarios
        </NavItem>
        </div>
        <Button>Cerrar sesion</Button>
      </div>
      <TabContent className='admin_tabs' activeTab={activeTab}>
        <TabPane className={`admin_Tab ${activeTab === 'Orders' ? 'admin_Tab_active' : ''}`} tabId="Orders">
          <h1>Orders</h1>
        </TabPane>
        <TabPane className={`admin_tab ${activeTab === 'Products' ? 'admin_Tab_active' : ''}`} tabId="Products">
          <div className='admin_tab_content'>
            <ProductsTab/>
          </div>
        </TabPane>
        <TabPane className={`admin_Tab ${activeTab === 'Users' ? 'admin_Tab_active' : ''}`} tabId="Users">
          <h1>Users</h1>
        </TabPane>
      </TabContent>
    </div>
  )
}

const mapDistpatchToProps = dispatch => {
  return{
    logIn:(userName, password)=>{return dispatch(sessionActions.logIn(userName,password))},
    getAdminProducts:(query) => {return dispatch(productsActions.loadAdminProducts(query))} ,
  }
}

const connectedAdmin = connect(
  null,
  mapDistpatchToProps
)(AdminScreen)

export default connectedAdmin