import { createAction } from "redux-actions";

export const types = {
    createUnitInProgress: "createUnitInProgress",
    createUnitSuccess: "createUnitSuccess",
    createUnitFailed: "createUnitFailed",

    getUnitsInProgress: "getUnitsInProgress",
    getUnitsSuccess: "getUnitsSuccess",
    getUnitsFailed: "getUnitsFailed",
}

const createUnitInProgress = createAction(types.createUnitInProgress)
const createUnitSuccess = createAction(types.createUnitSuccess)
const createUnitFailed = createAction(types.createUnitFailed)

const getUnitsInProgress = createAction(types.getUnitsInProgress)
const getUnitsSuccess = createAction(types.getUnitsSuccess)
const getUnitsFailed = createAction(types.getUnitsFailed)

const createUnit = (name, shortName, successCallback, failCallback) => {
    return (dispatch, getState, Api)=>{
        dispatch(createUnitInProgress())
        return Api.UNITS.new(name,shortName)
            .then((res)=>{
                dispatch(createUnitSuccess(res.data))
                if(successCallback){
                    successCallback(res)
                }
            })
            .catch((err)=>{
                dispatch(createUnitFailed(err))
                if(failCallback){
                    failCallback(err)
                }
            })
    }
}

const getUnits = (successCallback, failCallback) =>{
    return (dispatch, getState, Api)=>{
        dispatch(getUnitsInProgress())
        return Api.UNITS.get()
        .then((res)=>{
            dispatch(getUnitsSuccess(res))
            if(successCallback){
                successCallback(res)
            }
        })
        .catch((err)=>{
            dispatch(getUnitsFailed(err))
            if(failCallback){
                failCallback(err)
            }
        })

    }
}

export const UnitsActions ={
    createUnit,
    getUnits,
}