import React, { useEffect, useRef, useState } from 'react'
import { Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, TabContent, TabPane } from 'reactstrap'
import ProductsActions from '../../store/products/actions'

import './styles.scss'
import { connect } from 'react-redux';
import axios from 'axios';
import { URL_BASE } from '../../constants/url';
import Swal from 'sweetalert2';
import { Row, Col, Container, Dropdown, DropdownItem, DropdownMenu} from 'reactstrap'

const ImageInput = ({For, Title, ImageFile, width='100px', height="100px", Name, OnChange, onDelete, disabled = false, currentImage}) => {

    const urlImage = currentImage ? `${URL_BASE}/${currentImage}` : ImageFile ? URL.createObjectURL(ImageFile) : ''

    return (
        <div className='imageInput_container'>
            <div className={`imageInput`} style={{
                backgroundImage: `url(${urlImage})`,
                width,
                height
            }}>
                <label htmlFor={For}> 
                    {!ImageFile ? 
                        !disabled ? 
                            <div className='imageInput_add'><i className='fa fa-plus'/>{currentImage ? "Cambiar" : "Agregar"}</div>
                        : <i className='fa fa-times imageInput_cantAdd'/>
                    : null}
                </label>
                <p>{Title}</p>
                {ImageFile ? <i onClick={()=>onDelete(Name)} className='fa fa-times imageInput_delete' /> : null}
                {!disabled ?
                    <Input hidden type="file" accept="image/*" name={Name} id={For} onChange={OnChange} />
                :null}
                
            </div>
        </div>)
        
}

const editProducModal = ({
    open,
    closeModal,
    categories,
    unitTypes,
    sizes,
    productStyles,
    product
}) => {
    if(!product) return null
    
    const initialState = {
        ...product,
        image1: null,
        image2: null,
        image3: null,
        image4: null
    }

    const [selectedImages, setSelectedImages] = useState({});
    const [formData, setFormData] = useState(initialState);
    const [selectedStyles, setSelectedStyles] = useState([])
    const [selectedSizes, setSelectedSizes] = useState([])
    const [dropdownStylesOpen, setDropdownStylesOpen] = useState(false)
    const [dropdownSizesOpen, setDropdownSizesOpen] = useState(false)

    const resetForm = () => {
        setFormData(initialState)
    }

    const handleClose = () => {
        resetForm();
        closeModal();
        setSelectedImages({});
        setSelectedStyles([]);
        setSelectedSizes([]);
        setDropdownStylesOpen(false);
        setDropdownSizesOpen(false);
    }

    useEffect(() => {
        setFormData(old=>({
            ...old,
            ...selectedImages
        }))
    }, [selectedImages])

    useEffect(() => {
      console.warn(formData);
    }, [formData])

    useEffect(() => {
        setFormData({
            ...formData,
            stylesIds: selectedStyles?.map(style=>style.id).join(','),
        });
    }, [selectedStyles])

    useEffect(() => {
        setFormData({
            ...formData,
            sizesIds: selectedSizes?.map(size=>size.id).join(','),
        });
    }, [selectedSizes])
    
    const openStylesDropdown = () => setDropdownStylesOpen(true);
    const toggleStylesDropdown = () => setDropdownStylesOpen(prevState => !prevState);
  
    const openSizesDropdown = () => setDropdownSizesOpen(true);
    const toggleSizesDropdown = () => setDropdownSizesOpen(prevState => !prevState);

    const handleEditProduct = () => {
        const data = new FormData()
        for (var key in formData) {
              data.append(key, formData[key]);
          }
        axios.post(`${URL_BASE}/php/newProduct.php`, data).then(res=>{
            if(res){
                Swal.fire({
                    icon: 'success',
                    html: "Producto creado con exito",
                    showConfirmButton: false,
                    showCloseButton: true,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                })
                getPrducts();
                handleClose();
            }else{
                Swal.fire({
                    icon: 'error',
                    html: "Error al crear el producto",
                    showConfirmButton: false,
                    showCloseButton: true,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                })
            }
        })
        .catch(err=>{
            Swal.fire({
                icon: 'error',
                html: "parece haber problemas al comunicarse con la base de datos, intente en unos minutos",
                showConfirmButton: false,
                showCloseButton: true,
                allowEscapeKey: false,
                allowEnterKey: false,
            })
        })
    }

    const handleInputChange = (event) => {
        const { name, value, checked, type } = event.target;
        console.warn(name, type != 'checkbox' ? value : checked);
        setFormData({
            ...formData,
            [name]: type != 'checkbox' ? value : checked,
        });
    };

    const handleAddStyle = (style) => {
        setSelectedStyles(old=>{
            return [...old, style]
        })
    }

    const handleRemoveStyle = (style) =>{
        setSelectedStyles(old=>{
            return old.filter(oldstyle=>oldstyle.id != style.id)
        })
    } 

    const handleAddSize = (size) => {
        setSelectedSizes(old=>{
            return [...old, size]
        })
    }

    const handleRemoveSize = (size) =>{
        setSelectedSizes(old=>{
            return old.filter(oldsize=>oldsize.id != size.id)
        })
    } 

    const handleImageChange = (event) => {
        const imageFile = event.target.files[0];
        if (imageFile) {
            setSelectedImages(old=>(
                {
                    ...old,
                    [event.target.name]:imageFile,
                }
            ));
        };
    }

    const handleDeleteImage = (name) => {
        const images = {...selectedImages};
        delete images[name]
        setSelectedImages(images)
    }

    const handleSubmit = (event) => {
        event.preventDefault();
    }
    
  return (
    <Modal isOpen={open} size='xl' className='newProductModal'>
        <ModalHeader>
            <h3>Crear producto</h3>
        </ModalHeader>
        <Form id='newProductForm' onSubmit={handleSubmit}>
        <ModalBody>
        <Container>
        <Label for="unitType">Imagenes</Label>
            <div className='newProductImages_list'>
                <ImageInput
                    Name="image1"
                    For='image1'
                    Title="Imagen 1"
                    width="150px"
                    height="200px"
                    ImageFile={selectedImages?.image1}
                    OnChange={handleImageChange}
                    onDelete={handleDeleteImage}
                    currentImage={product.images[0]?.path}
                />
                <ImageInput
                    Name="image2"
                    For='image2'
                    Title="Imagen 2"
                    width="150px"
                    height="200px"
                    ImageFile={selectedImages?.image2}
                    OnChange={handleImageChange}
                    onDelete={handleDeleteImage}
                    disabled={!selectedImages?.image1 && !product.images[1]?.path}
                    currentImage={product.images[1]?.path}
                />
                <ImageInput
                    Name="image3"
                    For='image3'
                    Title="Imagen 3"
                    width="150px"
                    height="200px"
                    ImageFile={selectedImages?.image3}
                    OnChange={handleImageChange}
                    onDelete={handleDeleteImage}
                    disabled={!selectedImages?.image2 && !product.images[2]?.path}
                    currentImage={product.images[2]?.path}
                />
                <ImageInput
                    Name="image4"
                    For='image4'
                    Title="Imagen 4"
                    width="150px"
                    height="200px"
                    ImageFile={selectedImages?.image4}
                    OnChange={handleImageChange}
                    onDelete={handleDeleteImage}
                    disabled={!selectedImages?.image3 && !product.images[3]?.path}
                    currentImage={product.images[3]?.path}
                />
            </div>
                <Row>
                    <FormGroup>
                        <Label>Nombre</Label>
                        <Input type="text" name="name" id="name" value={formData.name} onChange={handleInputChange} />
                    </FormGroup>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label for="categoryId">Categoria</Label>
                            <Input type="select" name="categoryId" id="categoryId" value={formData.categoryId} onChange={handleInputChange}>
                                {categories.map(category=>(
                                    <option key={category.id} value={category.id}>{category.name}</option>
                                ))}
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label for="unitType">Unidad de medida</Label>
                            <Input type="select" name="unitTypeId" id="unitType" value={formData.unitTypeId} onChange={handleInputChange}>
                                {unitTypes.map(unitType=>(
                                    <option key={unitType.id} value={unitType.id}>{unitType.name}</option>
                                ))}
                            </Input>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label for="units">Stock</Label>
                            <Input type="number" step="1" min="0" name="stock" id="stock" value={formData.stock} onChange={handleInputChange} />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label for="kgFix">Precio por {unitTypes.find(unit=>(unit.id == formData?.unitTypeId))?.shortName}</Label>
                            <Input type="number" step="0.01" min="0" max="1000" defaultValue={0}  name="pricePerUnitType" id="pricePerUnitType" value={formData.pricePerUnitType} onChange={handleInputChange} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Label for="kgFix">Estilos <i className='fa fa-plus' onClick={openStylesDropdown}/></Label>
                        <Dropdown className='newProducModal_stylesSelector' onMouseLeave={toggleStylesDropdown} isOpen={dropdownStylesOpen} toggle={toggleStylesDropdown}>
                            <DropdownMenu>
                                {productStyles.filter(style=>{
                                    return !selectedStyles.map(selectedStyle=>selectedStyle.id).includes(style.id)
                                })?.map(style => {
                                    return <DropdownItem className='newProducModal_stylesSelector_item' onClick={()=>handleAddStyle(style)}>
                                        <div className='selectedStyleItem_data_name'>{style.name}</div>
                                            <div className='selectedStyleItem_data_value'>{style.textValue}</div>
                                    </DropdownItem>
                                })}
                            </DropdownMenu>
                        </Dropdown>
                        <div className='selectedStyles'>
                            {selectedStyles.map(style=>{
                                return (
                                    <div className='selectedStyleItem'>
                                        <div className='selectedStyleItem_data'>
                                            <div className='selectedStyleItem_data_name'>{style.name}</div>
                                            <div className='selectedStyleItem_data_value'>{style.textValue}</div>
                                        </div>
                                        <div className='selectedStyleItem_remove' onClick={()=>handleRemoveStyle(style)}>
                                            <i className='fa fa-times' />
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </Col>
                    <Col>
                        <Label for="kgFix">Tamaños <i className='fa fa-plus' onClick={openSizesDropdown}/></Label>
                        <Dropdown className='newProducModal_stylesSelector' onMouseLeave={toggleSizesDropdown} isOpen={dropdownSizesOpen} toggle={toggleSizesDropdown}>
                            <DropdownMenu>
                                {sizes.filter(size=>{
                                    return selectedSizes.map(selectedSize=>selectedSize.id).includes(size.id) && size.unitTypeId == formData.unitTypeId
                                })?.map(style => {
                                    return <DropdownItem className='newProducModal_stylesSelector_item' onClick={()=>handleAddSize(style)}>
                                        <div className='selectedStyleItem_data_name'>{style.name}</div>
                                            <div className='selectedStyleItem_data_value'>{style.sizes}</div>
                                    </DropdownItem>
                                })}
                            </DropdownMenu>
                        </Dropdown>
                        <div className='selectedStyles'>
                            {selectedSizes.map(size=>{
                                return (
                                    <div className='selectedStyleItem'>
                                        <div className='selectedStyleItem_data'>
                                            <div className='selectedStyleItem_data_name'>{size.name}</div>
                                            <div className='selectedStyleItem_data_value'>{size.sizes}</div>
                                        </div>
                                        <div className='selectedStyleItem_remove' onClick={()=>handleRemoveSize(size)}>
                                            <i className='fa fa-times' />
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <Input type='checkbox' name="inOffer" id="inOffer" value={formData.inOffer} onChange={handleInputChange} />
                            <Label for="inOffer">Oferta</Label>
                        </FormGroup>
                    </Col>
                </Row>
            </Container>
        </ModalBody>
        <ModalFooter>
            <div className='modal_buttons'>
                <Button className='btn btn-danger' onClick={handleClose} >Cerrar</Button>
                <Button className='btn btn-success' onClick={handleEditProduct}>Crear</Button>
            </div>
        </ModalFooter>
        </Form>
    </Modal>
  )
}

const mapStateToProps = (state) => {
    return {
        categories: state.categories.list,
        unitTypes: state.units.list,
        sizes: state.sizes.list,
        productStyles: state.productsStyles.list
    }
}

const mapDistpatchToProps = (dispatch) => {
    return {
        createProduct:(data)=>dispatch(ProductsActions.newProduct(data))
    }
}

const EditProductModal = connect(
mapStateToProps,
mapDistpatchToProps
)(editProducModal)

export default EditProductModal