import { createAction } from "redux-actions";

export const types = {
    createSizeInProgress: "createSizeInProgress",
    createSizeSuccess: "createSizeSuccess",
    createSizeFailed: "createSizeFailed",

    getSizesInProgress: "getSizesInProgress",
    getSizesSuccess: "getSizesSuccess",
    getSizesFailed: "getSizesFailed",
}

const createSizeInProgress = createAction(types.createSizeInProgress)
const createSizeSuccess = createAction(types.createSizeSuccess)
const createSizeFailed = createAction(types.createSizeFailed)

const getSizesInProgress = createAction(types.getSizesInProgress)
const getSizesSuccess = createAction(types.getSizesSuccess)
const getSizesFailed = createAction(types.getSizesFailed)

const createSize = (name, sizes, unitTypeId, successCallback, failCallback) => {
    return (dispatch, getState, Api)=>{
        dispatch(createSizeInProgress())
        return Api.SIZES.new(name,sizes,unitTypeId)
            .then((res)=>{
                dispatch(createSizeSuccess(res.data))
                if(successCallback){
                    successCallback(res)
                }
            })
            .catch((err)=>{
                dispatch(createSizeFailed(err))
                if(failCallback){
                    failCallback(err)
                }
            })
    }
}

const getSizes = (successCallback, failCallback) =>{
    return (dispatch, getState, Api)=>{
        dispatch(getSizesInProgress())
        return Api.SIZES.get()
        .then((res)=>{
            dispatch(getSizesSuccess(res))
            if(successCallback){
                successCallback(res)
            }
        })
        .catch((err)=>{
            dispatch(getSizesFailed(err))
            if(failCallback){
                failCallback(err)
            }
        })

    }
}

export const SizesActions ={
    createSize,
    getSizes,
}