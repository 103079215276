import { createAction } from "redux-actions";

export const types = {
    addToCart: 'addToCart',
    removerFromCart: 'removeFromCart',
    updateItem: 'updateItem',
    clearCart: 'clearCart',
    openCart: 'openCart',
    closeCart: 'closeCart',
}

const addToCart = createAction(types.addToCart)
const removerFromCart = createAction(types.removerFromCart)
const updateItem = createAction(types.updateItem)
const clearCart = createAction(types.clearCart)
const openCart = createAction(types.openCart)
const closeCart = createAction(types.closeCart)

export default {
    addToCart,
    removerFromCart,
    updateItem,
    clearCart,
    openCart,
    closeCart
}