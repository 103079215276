import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Table from '../../components/TableComponent'
import productsActions from '../../store/products/actions'
import { getLoadingProducts, getProducts } from '../../store/products/selector'

import './styles.scss'

const HomeScreen = ({

}) => {

  useEffect(() => {

  }, [])

  return (
    <div className='home'>

    </div>
  )
}

const mapDistpatchToProps = dispatch => {
  return {
    getProducts:()=>{return dispatch(productsActions.loadProducts())}
  }
}

const mapStateToProps = state => {
  return {

  }
}

const connectedHome = connect(
  mapStateToProps,
  mapDistpatchToProps
)(HomeScreen)

export default connectedHome