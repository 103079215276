import {handleActions} from "redux-actions";
import { types } from "./actions";

const initialState = {
    products:[],
    totalAmount: 0,
    open: false
}

const reducer = handleActions({
    [types.addToCart]:(state, action)=>{
        const newList = state.products.find(prod=>prod.productId == action.payload.productId) //if product exist in list
        ? state.products.map(product => {
            console.warn(action.payload);
            if(product.productId == action.payload.productId){

                const { quantity, pricePerUnitType, style } = action.payload

                const newQuantity = ((product.size || product.customSizes) ? quantity : product.quantity + quantity) * ( style ? style.numberValue : 1)

                const newTotal = newQuantity * pricePerUnitType

                return {
                    ...(product),
                    ...(action.payload),
                    totalAmount: newTotal,
                    quantity: newQuantity
                }

            }else{
                return product
            }
        }) 
        : 
            [...(state.products) , {
                ...(action.payload),
                totalAmount: (action.payload.quantity * action.payload.pricePerUnitType) * ( action.payload.style ? action.payload.style.numberValue : 1),
            } ]

        const newTotal = newList.map(product=>product.totalAmount).reduce((acc, value)=>acc+value, 0)
        return {
            ...state,
            products: newList,
            totalAmount: newTotal
        }
    },
    [types.updateItem]: (state, action) => {
        const newList = state.products.map(product => {
            if(product.productId == action.payload.productId){

                const { quantity, pricePerUnitType, style } = action.payload            

                const newTotal = (quantity * pricePerUnitType)

                return {
                    ...(product),
                    totalAmount: newTotal,
                    quantity
                }

            }else{
                return product
            }
        })
        const newTotal = newList.map(product=>product.totalAmount).reduce((acc, value)=>acc+value, 0)
        return {
            ...state,
            products: newList,
            totalAmount: newTotal
        }
    },
    [types.removerFromCart]:(state, action)=>{
        const newList = state.products.filter(product => product.productId != action.payload)
        const newTotal = newList.map(product=>product.totalAmount).reduce((acc, value)=>acc+value, 0)
        return {
            ...state,
            products: newList,
            totalAmount: newTotal
        }
    },
    [types.clearCart]:(state)=>{
        return {
            ...state,
            products: []
        }
    },
    [types.openCart]: (state) => {
        return {
            ...state,
            open: true
        }
    },
    [types.closeCart]: (state) => {
        return {
            ...state,
            open: false
        }
    }
},initialState)

export default reducer