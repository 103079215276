import { createAction } from "redux-actions";
import authActions from "../auth/actions"

export const types = {
    loginInProgress:"logginInProgres",
    loginSuccess:"loginSuccess",
    loginFailed:"loginFailed",
    //---------------------
    logInByTokenInProgres:"logInByTokenInProgres",
    logInByTokenSuccess:"logInByTokenInSuccess",
    logInByTokenFailed:"logInByTokenFailed",
    //---------------------
    logOut:"logOut",
    //---------------------
    registerInProgress:"registerInProgress",
    registerSuccess:"registerSuccess",
    registerFailed:"registerFailed",
}

const loginInProgress = createAction(types.loginInProgress)
const loginSuccess = createAction(types.loginSuccess)
const loginFailed = createAction(types.loginFailed)

const logInByTokenInProgres = createAction(types.logInByTokenInProgres)
const logInByTokenSuccess = createAction(types.logInByTokenSuccess)
const logInByTokenFailed = createAction(types.logInByTokenFailed)

const registerInProgress = createAction(types.registerInProgress)
const registerSuccess = createAction(types.registerSuccess)
const registerFailed = createAction(types.registerFailed)

const logOutAction = createAction(types.logOut)

const validateEmailExist = () => {
    return (dispatch, getState, Api)=>{
        return {action:(email)=>Api.SESSION.validateEmailExist(email)}
    }
}

const register = (data, successCallback, failCallback) => {
    return (dispatch, getState, Api)=>{
        dispatch(registerInProgress())
        
        return Api.SESSION.register(data)
        .then((res)=>{
            dispatch(registerSuccess(res.data))
            successCallback()
            authActions.saveAuthorization(res.data.token)
        }).catch((err)=>{
            dispatch(registerFailed(err))
            failCallback()
        })
    }

}

const logIn = (email, password) => {
    return (dispatch, getState, Api)=>{
        dispatch(loginInProgress())
        
        return Api.SESSION.logIn({email,password})
        .then((res)=>{
            if(res.data?.type == 'error'){
                dispatch(loginFailed(err))
            }else{
                dispatch(loginSuccess(res.data))
                authActions.saveAuthorization(res.data.token)
            }
        }).catch((err)=>{
            dispatch(loginFailed(err))
        })
    }
}

const tryLoginByToken = () => {
    return (dispatch, getState, Api) => {
        dispatch(logInByTokenInProgres())

        return Api.SESSION.logInByToken()
            .then((res)=>{
                const { data } = res
                dispatch(logInByTokenSuccess(data))
            }).catch((err)=>{
                dispatch(logInByTokenFailed(err))
            })
    }
}

const validateAccount = () => {
    return (dispatch, getState, Api) => {
        return {
            action: (token) => Api.SESSION.validateAccount(token)   
        } 
    }
}

const logOut = () => {
    return (dispatch, getState, api)=>{
        dispatch(logOutAction())

        return Promise.resolve()
    }
}

export default {
    logIn,
    logOut,
    tryLoginByToken,
    register,
    validateEmailExist,
    validateAccount
}