import {handleActions} from "redux-actions";
import { types } from "./actions";

const initialState = {
    createUnitInProgress:false,
    createUnitSuccess:false,
    createUnitFailed:false,
    getUnitsInProgress:false,
    getUnitsSuccess:false,
    getUnitsFailed:false,
    list:[]
}

const reducer = handleActions({
    [types.createUnitInProgress]:state =>({
        ...state,
        createUnitInProgress: true,
        createUnitSuccess: false,
        createUnitFailed: false
    }),
    [types.createUnitSuccess]:(state, action) =>({
        ...state,
        createUnitInProgress: false,
        createUnitSuccess: true,
        createUnitFailed: false
    }),
    [types.createUnitFailed]:(state) =>({
        ...state,
        createUnitInProgress: false,
        createUnitSuccess: false,
        createUnitFailed: true
    }),
    [types.getUnitsInProgress]:state =>({
        ...state,
        getUnitsInProgress: true,
        getUnitsSuccess: false,
        getUnitsFailed: false
    }),
    [types.getUnitsSuccess]:(state, action) =>({
        ...state,
        getUnitsInProgress: false,
        getUnitsSuccess: true,
        getUnitsFailed: false,
        list: action.payload.data.list
    }),
},initialState)

export default reducer