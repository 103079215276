import Swal from 'sweetalert2';
import { store } from '../index'
import { SimpleAjax } from '../../services/simpleAjax'
import { isString } from 'lodash'
import { URL_BASE } from '../../constants/url';

const Sajax = new SimpleAjax(`${URL_BASE}/php`)

Sajax.addRequestInterceptor((requestData) => {
    // Añadir un encabezado personalizado
    requestData.data = { 
        ...requestData.data,
        "Authorizartion": store.getState().auth.token
    }
    // Modificar los datos de la solicitud
    //requestData.data = { ...requestData.data, timestamp: Date.now() };
  
    return requestData;
  });

Sajax.addResponseInterceptor((responseData) => {
    console.warn("responseData: ", responseData);

    const decodedResponse = isString(responseData) ? {
        data: JSON.parse(responseData)
    } : responseData

    if(decodedResponse.data.swal){
        Swal.fire({
            icon: decodedResponse.data.type,
            html: decodedResponse.data.message || decodedResponse.data.error,
            showConfirmButton: false,
            showCloseButton: true,
            allowEscapeKey: false,
            allowEnterKey: false,
        })

        return {
            ...decodedResponse}
    }
    
    return decodedResponse;

  });


export const API = {

    SESSION:{
        logIn:(userAndPass)=>{
            console.warn("userAndPass", userAndPass);
            return Sajax.post('/userLogin.php', {...userAndPass})
        },
        logInByToken:()=>{
            return Sajax.post('/userLoginByToken.php');
        },
        register:(data)=>{
            return Sajax.post('/userRegister.php', data)
        },
        validateAccount:(token)=>{
            return Sajax.post('/userValidateAccount.php', {token})
        }
    },

    ORDERS:{
        new:(orderData)=>{
            return Sajax.post('/newOrder.php', orderData)
        },
        getOrderByToken:(token)=>{
            return Sajax.get(`/getOrderByToken.php`, {token})
        },
        getOrderByPreferenceId:(preferenceId)=>{
            return Sajax.get(`/getOrderByPreferenceId.php`, {preferenceId})
        }
    },
    
    PRODUCTS:{
        get:(query)=>{
            return Sajax.get(`/getProducts.php`, {...query});
        },
        getById:(id)=>{
            return Sajax.get(`/getProductById.php`, {productId:id});
        },
        AdminGet:(query)=>{
            return Sajax.get(`/getAdminProducts.php`, {...query});
        },
        new:(productData)=>{
            return Sajax.post('/newProduct.php', productData)
        },
        delete:(productId)=>{
            return Sajax.post('/deleteProduct.php', {productId})
        },
        setStatus:(productId,status)=>{
            return Sajax.post('/setProductStatus.php', {productId,status})
        }
    },

    CATEGORIES:{
        get:()=>{
            return Sajax.get(`/getCategories.php`);
        },
        new:(name)=>{
            return Sajax.post('/newCategory.php', {name}) 
        }
    },

    UNITS:{
        get:()=>{
            return Sajax.get(`/getUnits.php`);
        },
        new:(name,shortName)=>{
            return Sajax.post('/newUnit.php', {name,shortName}) 
        }
    },

    SIZES:{
        get:()=>{
            return Sajax.get(`/getSizes.php`);
        },
        new:(name,sizes,unitTypeId)=>{
            return Sajax.post('/newSize.php', {name,sizes,unitTypeId}) 
        }
    },

    STYLES:{
        get:()=>{
            return Sajax.get(`/getStyles.php`);
        },
        new:(name)=>{
            return Sajax.post('/newStyle.php', {name}) 
        }
    }

}