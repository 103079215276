import React, { useEffect, useState } from "react";
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
  Card,
  CardBody,
  Input,
  Button,
} from "reactstrap";
import { URL_BASE } from "../../../constants/url";
import "../styles.scss";
import { connect } from "react-redux";
import CartActions from "../../../store/cart/actions";

import "./styles.scss";
import { productStatusTypes, sheepProductId } from "../../../constants";

const UnConnectedProductItem = ({ 
  product, 
  addToCart, 
  onClickEdit, 
  cartItems,
  openCart,
  unitTypes,
  sizes,
  categories,
  productsStyles,
  sheepOnClick
}) => {
  if (!product) return;

  const productStyles = product.stylesIds ? productsStyles.filter(prodStyle => {
    return product.stylesIds.includes(prodStyle.id)
  }) : null

  const [activeIndex, setActiveIndex] = useState(0);
  const [selectedSize, setSelectedSize] = useState(null)
  const [selectedStyle, setSelectedStyle] = useState( productStyles ? productStyles[0] : null )
  const [selectedCuts, setSelectedCuts] = useState( product.cuts ? 'Ninguno': null )
  const [animating, setAnimating] = useState(false);
  const [quantity, setquantity] = useState(0);

  const inCart = cartItems.find((item) => item.product_id === product.id);
 
  const items = product.images?.map((image, index) => ({
    src: `${URL_BASE}/${image.path}`,
    key: index,
  }));

  const handleAddToCart = () => {
    
    const { inOffer, pricePerUnitType, unitType, name, images, customSizes } = product;
    addToCart({
      productId: product.id,
      //orderId
      unitType: product.unitType,
      customSizes,
      size: selectedSize,
      style: selectedStyle,
      pricePerUnitType,
      quantity: quantity,
      name,
      cuts: selectedCuts,
      images,
      inOffer
    });
    setquantity(0);
  };

  const handleIncrementquantity = () => {
    if(inCart) return;
    setquantity(Number(quantity) + Number(product?.unitType?.steps));
  };

  const handleDecrementquantity = () => {
    if(inCart) return;
    let result = Number(quantity) - Number(product?.unitType?.steps);
    if (result <= 0) {
      setquantity(0);
    } else {
      setquantity(Number(quantity) - Number(product?.unitType?.steps));
    }
  };

  const handleChangequantity = (e) => {
    if(inCart) return;
    setquantity(e.target.value);
  };

  const handleSelectSize = (e) => {
    if(e.target.value == 0) return
    const size = sizes.find((s) => s.id === Number(e.target.value));
    setSelectedSize(size);
  };

  const handleSelectStyle = (e) => {
    if(e.target.value == 0) return
    const style = productsStyles.find((s) => s.id === Number(e.target.value));
    setSelectedStyle(style);
  };

  const handleSelectCuts = (e) => {
    setSelectedCuts(e.target.value);
  }

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const handleSheepOnClick = () => {
    if(product.id == sheepProductId){
      sheepOnClick()
    }
  }

  const slides = items
    ? items.map((item, index) => {
        return (
          <CarouselItem
            onExiting={() => setAnimating(true)}
            onExited={() => setAnimating(false)}
            key={item?.src + index}
            style={{
              padding: "0px 10px",
            }}
          >
            <div
              style={{
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "contain",
                width: "100%",
                height: "200px",
                backgroundImage: `url(${item.src})`,
                backgroundColor: "white",
              }}
            />
          </CarouselItem>
        );
      })
    : [];

  const hasquantity = quantity > 0  

  return (
    <div className="productItem_container" style={product.id == sheepProductId ? {cursor:'pointer'} : null} onClick={handleSheepOnClick}>
      {product.inOffer ? 
        <div className="productItem_inOffer">
          ¡Oferta!
        </div>
      :null}
      {product.status == productStatusTypes.DISABLED ?
        <div className="productItem_disabled">
          Deshabilitado
        </div>
      : null}
      <Carousel
        fade
        mouseEnter={next}
        mouseLeave={previous}
        activeIndex={activeIndex}
        next={next}
        previous={previous}
      >
        {slides}
        {product.images.length > 1 ? (
          <>
            <CarouselIndicators
              items={items}
              activeIndex={activeIndex}
              onClickHandler={goToIndex}
            />
            <CarouselControl
              className="carouselBtn"
              direction="prev"
              directionText="Previous"
              onClickHandler={previous}
            />
            <CarouselControl
              direction="next"
              directionText="Next"
              onClickHandler={next}
            />
          </>
        ) : null}
      </Carousel>
        <div className="productItem_header">
          <label className="productItem_name">{product.name}</label>
            <label className="productItem_price">
              ${product.pricePerUnitType} x {product.unitType.shortName}
            </label>
        </div>
        <div className="productItem_body">
          <div>
            {product.description}
          </div>
          {product.sizesIds?.length > 0 ? (
            <>
              <div className="productItem_selector_container">
                <div className="productItem_selector">
                  {inCart ? 
                    <div className="productItem_selector_inCart"/>
                  :null}
                  <label>Tamaño</label>
                  <select disabled={inCart} onChange={handleSelectSize}>
                    <option>Seleccione..</option>
                    {product.sizesIds.split(",").map((sizeId) => {
                      const size = sizes.find((size) => size?.id == sizeId);
                      return (
                        <option selected={selectedSize?.id == size?.id} value={size?.id} id={product.name+size.id}>
                          {size?.name}
                        </option>
                      ) 
                    })}
                  </select>
                </div>
              </div>
              <div className="productItem_selector_container">
                <div className="productItem_selector">
                  <label>Peso</label>
                  <select onChange={handleChangequantity}>
                    <option>Seleccione..</option>
                    {selectedSize?.sizes.split(",").map((size) => {
                      const unitType = unitTypes.find((unit) => unit.id == selectedSize.unitTypeId); 
                      return (
                        <option value={size} id={selectedSize.id+size}>
                          {size} {unitType.shortName}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              {product.cuts ? (
                <div className="productItem_selector_container">
                <div className="productItem_selector">
                  <label>Cortes</label>
                  <select onChange={handleSelectCuts} value={selectedCuts}>
                    <option>Ninguno</option>
                    {product.cuts.split(",").map((cut) => {
                      return (
                        <option value={cut} id={product.name+"cut"+cut} selected={selectedCuts == cut}>
                          {cut}
                        </option>
                      )
                    })}
                  </select>
                  </div>
                </div>
              ) : null}
            </>
          ) : product?.customSizes ? (
            <div className="productItem_selector_container">
              <div className="productItem_selector">
                <label>Peso</label>
                <select onChange={handleChangequantity}>
                  {product?.customSizes.split(",").map((size) => {
                    return (
                      <option value={size} id={"customSize"+size}>
                        {size} {product.unitType.shortName}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          ) : product.id != sheepProductId ?
            <div className="productItem_kgInput_container">
              {inCart ? 
                <div className="productItem_kgInput_inCart"/>
              :null}
              <label>Peso</label>
              <div className="productItem_kgInput_input">
                <i className="fa fa-minus" onClick={handleDecrementquantity} />
                <div className="productItem_kgInput_input_value">
                  {quantity}
                </div>
                <i className="fa fa-plus" onClick={handleIncrementquantity} />
              </div>
            </div>
          : null
          }
          {!inCart ? (
            <div className={`productItem_addToCart ${hasquantity ? 'canAdd' : ''} `} onClick={handleAddToCart}>
              Agregar al carrito
            </div>
          ) : (
            <div className="productItem_inCart" onClick={openCart}>
              Ver en el carrito
            </div>
          )}
          {product.stylesIds?.length > 0 ? (
          <>
            <div className="productItem_selector_container">
              <div className="productItem_selector">
                <label>Estilo</label>
                <select onChange={handleSelectStyle}>
                  {product.stylesIds.split(",").map((styleId) => {
                    const style = productsStyles.find((style) => style?.id == styleId);
                    return (
                      <option value={style.id} id={style.id+style.name} selected={selectedStyle?.id == style?.id}>
                        {style.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              {selectedStyle?.textValue ? (
                <p>{selectedStyle?.textValue}</p>
              ) : null}
              </div>
          </>
        ) : null}
        </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  cartItems:state.cart.products,
  unitTypes:state.units.list,
  sizes:state.sizes.list,
  categories:state.categories.list,
  productsStyles:state.productsStyles.list,
});

const mapDistpatchToProps = (dispatch) => ({
  addToCart: (product) => dispatch(CartActions.addToCart(product)),
  openCart: () => dispatch(CartActions.openCart()),
});

const ProductItem = connect(
  mapStateToProps,
  mapDistpatchToProps
)(UnConnectedProductItem);

export default ProductItem;
