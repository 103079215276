import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ProducsActions from "../../store/products/actions";
import './styles.scss'
import { URL_BASE } from "../../constants/url";
import ProductItem from "./productItem";
import { useParams } from "react-router-dom";
import STPagination from "../../components/pagination";
import { sheepProductId } from "../../constants";
import { Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, TabContent, TabPane, Spinner } from 'reactstrap'

const customSheepImage = `${URL_BASE}/public/images/cordero_entero.jpg`

const ProductsScreen = ({ 
  loadProducts, 
  products, 
  categories, 
  isAdmin, 
  totalPages,
  loadingProducts,
  sizes,
  unitTypes
}) => {

  const {category} = useParams();

  const [activePage, setActivePage] = useState(1)
  const [customSheepOpen, setCustomSheepOpen] = useState(false)

  const [selectedSize, setSelectedSize] = useState(null)
  const [seletedWeight, setSeletedWeight] = useState(null)
  const [selectedStyle, setSelectedStyle] = useState(null)

  const limit = 15;
  useEffect(() => {
    loadProducts({
      category,
      limit,
      page: activePage
    });
  }, [category, activePage]);

  useEffect(() => {
    console.warn(category);
    setActivePage(1)
  }, [category])

  const openCustomSheep = () => {
    setCustomSheepOpen(true)
  }

  const closeCustomSheep = () => {
    setCustomSheepOpen(false)
  }

  const changePage = (localPage) => {
    setActivePage(localPage)
    loadProducts({
      category,
      limit,
      page: localPage,
    },isAdmin);
  }

  const styles = [
    {
      id: 1,
      title: "Abierto parrillero",
      subtitle: "(para la estaca)"
    },
    {
      id: 2,
      title: "Sin abrir",
      subtitle: ''
    }
  ]

  const CustomSheepBtn = () =>  (    
    <div className="productItem_container" onClick={openCustomSheep}>
        <div
            style={{
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize: "contain",
              width: "100%",
              height: "200px",
              backgroundImage: `url("${customSheepImage}")`,
              backgroundColor: "white",
            }}
          />
        <div className="productItem_header">
          <label className="productItem_name">
            Cordero entero
          </label>
          <label className="productItem_price">
            
          </label>
        </div>
        <div className="productItem_body">
          <div>
            Hacé click en esta tarjeta para personalizar tu cordero entero
          </div>
        </div>
    </div>
  )
  
  return (
    <div className="productsScreen">
      <Modal isOpen={customSheepOpen} style={{padding:0}} size={'lg'}>
        <div className='productsScreen_customSheep'>
          <div className="productsScreen_customSheep_header">
            <label>Personalice su cordero entero</label>
            <i className="fa fa-times" aria-hidden="true" onClick={closeCustomSheep}/>
          </div>
          <div className="productsScreen_customSheep_body">
            <label className="productsScreen_customSheep_title">Tamaños</label>
            <div className="productsScreen_customSheep_sizes">
              {sizes.map(size=>{
                return (
                  <div className="productsScreen_customSheep_size productsScreen_customSheep_selectable" onClick={()=>setSelectedSize(size)}>
                    <label>{size.name}</label>
                    {selectedSize?.id == size.id ?
                      <i className="fa fa-check"/>
                    : null}
                  </div>
                )
              })}
            </div>
            {selectedSize ? <>
              <label  className="productsScreen_customSheep_title" >Peso</label>
              <div className="productsScreen_customSheep_weights">
                {selectedSize?.sizes?.split(',')?.map(weight=>{
                  return (
                    <div className="productsScreen_customSheep_weight" onClick={()=>setSeletedWeight(weight)}>
                      <label>{weight}Kg</label>
                      {seletedWeight == weight ?
                        <i className="fa fa-check"/>
                      : null}
                    </div>
                  )
                })}
              </div>
            </> : null}
            {seletedWeight ? <>
              <label  className="productsScreen_customSheep_title" >Estilo</label>     
              <div className="productsScreen_customSheep_styles">       
                {styles.map(style=>{
                  return (
                    <div className="productsScreen_customSheep_style" onClick={()=>setSelectedStyle(style)}>
                      <label>{style.title}{" "}{style.subtitle}</label>
                      {style.id == selectedStyle?.id ?
                        <i className="fa fa-check"/>
                      : null}
                    </div>
                  )
                })}
              </div>
            </> : null}
          </div>
        </div>
      </Modal>
      <div className='productsScreen_container' >
        <div className="productsScreen_paggination">
          <STPagination
            activePage={activePage}
            totalPages={totalPages}
            changePage={changePage}
          />
        </div>
        <div className={`productsScreen_list`}>
          {!loadingProducts ? products.map((product) => {
            return <ProductItem product={product} sheepOnClick={openCustomSheep}/>
          }) : <Spinner color="dark" className="productsScreen_spinner"/>}
        </div>
      </div>
    </div>
  );
};

const mapDistpatchToProps = (dispatch) => ({
  loadProducts: (query) => {
      return dispatch(ProducsActions.loadProducts(query));
  },
});

const mapStateToProps = (state) => ({
  products: state.products.list,
  loadingProducts: state.products.getProductsInProgress , 
  categories: state.products.categories,
  totalPages: state.products.totalPages,
  sizes: state.sizes.list,
  unitTypes: state.units.list,
  sheepProduct: state.products.sheepData
});

const ConnectedProductScreen = connect(
  mapStateToProps,
  mapDistpatchToProps
)(ProductsScreen);

export default ConnectedProductScreen;
