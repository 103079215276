import React, { useEffect, useState } from 'react'
import { NavLink, Outlet } from 'react-router-dom'
import { connect } from 'react-redux'
import LoginModal from '../loginModal'
import SessionAcions from '../../store/session/actions'
import CartActions from '../../store/cart/actions'
import ProductsActions from '../../store/products/actions'

import './styles.scss'
import { Dropdown, DropdownItem, DropdownMenu } from 'reactstrap'
import Cart from '../cartComponent'
import { URL_BASE } from '../../constants/url'

const NavBar = ({
  validSession,
  isAdmin,
  logOut,
  user,
  cartCount,
  totalAmount,
  productCategories,
  cartOpen,
  openCart,
  closeCart,
  loadProducts
}) => {
  
  
  const [openLoginModal, setOpenLoginModal] = useState(false)
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')

  const activeLink = "active"

  const toggleCart = () => {
    if(cartOpen){
      closeCart()
    }else{
      openCart()
    }
  }

  const handleChangeSearchQuery = (e) => {
    setSearchQuery(e.target.value)
    if(e.target.value.length > 2){
      loadProducts({
        query: e.target.value
      });
    }else{
      loadProducts({
        limit
      })
    }
  }  

  const closeModal = ()=>setOpenLoginModal(false);

  const openDropdown = () => setDropdownOpen(true);
  const closeDropdown = () => setDropdownOpen(false);
  const toggleDropdown = () => setDropdownOpen(prevState => !prevState);

  const hadleClickUser = ()=>{
    if(validSession){
      openDropdown()
    }else{
      setOpenLoginModal(true)
    }
  }

  return (
    <div className='app-container'>
        <LoginModal isOpen={openLoginModal} closeModal={closeModal} />
        <div className='navBar'>
        <div className='navBar-container'>
            <div className='logo-container'>
              <div className='logo' style={{
                backgroundImage: `url(${URL_BASE}/public/images/HDL.png)`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'contain'
              }}>
              </div>
            </div>
            <div className='navBar-search'>
              <div className='searchBar'>
                <i className='fa fa-search ' />
                <input type='text' value={searchQuery} onChange={handleChangeSearchQuery}/>
              </div>
            </div>
            <div className='buttons'>
              <div className='button cartBtn' onClick={toggleCart} >
                <i className='fa fa-shopping-cart'/>
                {cartCount ? <div className='cart-count'>
                  ${totalAmount}
                </div> : null}
              </div>
              <div className={`button login ${validSession ? 'logged' : ''}`} onClick={hadleClickUser}>
                {validSession ? 
                  <>
                    <div className='logged_icon'>
                      <i className="fa-solid fa-user"></i>
                    </div>
                    <div className='logged_name'>
                      <p>{user?.firstName}</p>
                      <p>{user?.lastName}</p>
                    </div>
                  </>
                  :
                  <i className="fa-solid fa-user loginIcon"></i>
                }
                <Dropdown className='dropdownLogin' onMouseLeave={toggleDropdown} isOpen={dropdownOpen} toggle={toggleDropdown}>
                  <DropdownMenu>
                    <DropdownItem>Perfil</DropdownItem>
                    <DropdownItem>Mis pedidos</DropdownItem>
                    <DropdownItem onClick={logOut}>Cerrar sesion</DropdownItem>
                    {isAdmin ?
                    <> 
                      <DropdownItem divider/>
                      <DropdownItem>
                      <NavLink to="/admin" style={{textDecoration:'none', color:'black'}} className={
                        ({isActive})=> isActive ? activeLink : ''
                      }>
                        <b>Admin</b>
                      </NavLink>
                      </DropdownItem>
                    </>
                    :null}
                  </DropdownMenu>
                </Dropdown>
              </div>
            </div>
        </div>
        </div>
        <div className='navBar-categories navLinks'>
          <NavLink to="/products/0" className={
            ({isActive})=> isActive ? activeLink : ''
          }>
            Todos
          </NavLink>
          {
            productCategories?.map(category => (
              <NavLink to={`/products/${category.id}`} className={
                ({isActive})=> isActive ? activeLink : ''
              }>
                {category.name}
              </NavLink>
            ))
          }
        </div>
        <div className='viewsContent'>
          <Cart isOpen={cartOpen} close={closeCart}/>
          <Outlet/>
        </div>
    </div>
  )
}
const mapStateToProps = state => {
  return {
    validSession: state.session.validSession,
    isAdmin: state.session.user?.role === "ADMIN",
    user: state.session.user,
    cartCount: state.cart.products.length,
    totalAmount: state.cart.totalAmount,
    productCategories: state.categories.list,
    cartOpen: state.cart.open,
  }
}

const mapDistpatchToProps = dispatch => {
  return {
   logOut: ()=>dispatch(SessionAcions.logOut()),
   openCart: ()=>dispatch(CartActions.openCart()),
   closeCart: ()=>dispatch(CartActions.closeCart()),
   loadProducts: (query)=>dispatch(ProductsActions.loadProducts(query)),
  }
}

const connectedNavBar = connect(
  mapStateToProps,
  mapDistpatchToProps
)(NavBar)

export default connectedNavBar