import { combineReducers } from "redux";
import sesisonReducer from './session/reducer'
import appReducer from './application/reducer'
import productsReducer from './products/reducer'
import authReducer from './auth/reducer'
import cartReducer from './cart/reducers'
import ordersReducer from './orders/reducer'
import categoriesReducer from './categories/reducer'
import sizesReducer from './sizes/reducer'
import unitsReducer from './units/reducer'
import productsStylesReducer from './productStyles/reducer'
import popupReducer from './popup/reducer'

export default combineReducers({
    application:appReducer,
    auth:authReducer,
    session:sesisonReducer,
    products:productsReducer,
    cart:cartReducer,
    orders:ordersReducer,
    categories:categoriesReducer,
    sizes:sizesReducer,
    units:unitsReducer,
    productsStyles:productsStylesReducer,
    popup:popupReducer
})