import { createAction } from "redux-actions";

export const types = {
    createStyleInProgress: "createStyleInProgress",
    createStyleSuccess: "createStyleSuccess",
    createStyleFailed: "createStyleFailed",

    getStylesInProgress: "getStylesInProgress",
    getStylesSuccess: "getStylesSuccess",
    getStylesFailed: "getStylesFailed",
}

const createStyleInProgress = createAction(types.createStyleInProgress)
const createStyleSuccess = createAction(types.createStyleSuccess)
const createStyleFailed = createAction(types.createStyleFailed)

const getStylesInProgress = createAction(types.getStylesInProgress)
const getStylesSuccess = createAction(types.getStylesSuccess)
const getStylesFailed = createAction(types.getStylesFailed)

const createStyle = (name, successCallback, failCallback) => {
    return (dispatch, getState, Api)=>{
        dispatch(createStyleInProgress({name}))
        return Api.STYLES.new(name)
            .then((res)=>{
                dispatch(createStyleSuccess(res.data))
                if(successCallback){
                    successCallback(res)
                }
            })
            .catch((err)=>{
                dispatch(createStyleFailed(err))
                if(failCallback){
                    failCallback(err)
                }
            })
    }
}

const getStyles = (successCallback, failCallback) =>{
    return (dispatch, getState, Api)=>{
        dispatch(getStylesInProgress())
        return Api.STYLES.get()
        .then((res)=>{
            dispatch(getStylesSuccess(res))
            if(successCallback){
                successCallback(res)
            }
        })
        .catch((err)=>{
            dispatch(getStylesFailed(err))
            if(failCallback){
                failCallback(err)
            }
        })

    }
}

export const StylesActions ={
    createStyle,
    getStyles,
}