import { createAction } from "redux-actions";
import { CategoriesActions } from "../categories/actions";
import { SizesActions } from "../sizes/actions";
import { UnitsActions } from "../units/actions";
import { StylesActions } from "../productStyles/actions";
import { popupActions } from "../popup/actions";

export const types = {
    AppHasBooted:"AppHasBooted",
}

const AppHasBooted = createAction(types.AppHasBooted)

const bootApp = () => {
    return (dispatch, getState,api)=>{
        dispatch(AppHasBooted())
        dispatch(CategoriesActions.getCategories())
        dispatch(SizesActions.getSizes())
        dispatch(UnitsActions.getUnits())
        dispatch(StylesActions.getStyles())
        dispatch(popupActions.closePopup())

        return Promise.resolve()
    }
}

export default {
    bootApp
}