import React from 'react'
import { connect } from 'react-redux'

import './styles.scss'
import CartItem from './cartItem'
import { Link } from 'react-router-dom'

const CartComponent = ({isOpen, products, close, totalAmount}) => {
  return (
    <div className="cart">
        <div className={`cart_background ${isOpen ? 'cart_background_open' : ''}`} onClick={close} />
        <div className={`cart_container ${isOpen ? 'cart_container_open' : ''}`}>
            <div className='cart_header'>
                <div className='cart_closeBtn' onClick={close}>
                    Cerrar
                </div>
            </div>
            <div className='cart_list'>
                {products?.map(product=>{
                    return <CartItem product={product}/>
                })}
            </div>
            <div className='cart_footer'>
                <div className='cart_footer_totalAmount'>
                    Total: ${totalAmount}
                </div>
                {
                    totalAmount > 0 ? 
                        <Link to="/checkout" className='cart_checkout' onClick={close}>
                            Finalizar Compra
                        </Link>
                    : <div/>
                }
            </div>
        </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
    products: state.cart.products,
    totalAmount: state.cart.totalAmount
})

const mapDistpatchToProps = (dispatch) => ({
    
})

const Cart = connect(
    mapStateToProps,
    mapDistpatchToProps
)(CartComponent)

export default Cart