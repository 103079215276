import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { Form, FormGroup, Label, Input, Button, Row, Col } from 'reactstrap';
import CartItem from '../../components/cartComponent/cartItem'
import OrdersActions from '../../store/orders/actions'
import Swal from 'sweetalert2'

import './styles.scss'

const CheckoutScreen = ({
  cartItems,
  totalAmount,
  saveOrder,
  user,
  preferenceId
}) => {

  // Definir estados para los campos del formulario
  const [formData, setFormData] = useState({
    address: '',
    city: '',
    zipCode: '',
    users_id: '',
    comment: '',
  });

  // Manejar cambios en los campos del formulario
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Manejar el envío del formulario
  const handleSubmit = (e) => {
    saveOrder({
      address: formData,
      totalAmount,
      products: cartItems.map(item => ({
        ...item,
        styleName: item.style?.name,
        styleNumberValue: item.style?.numberValue,
        styleTextValue: item.style?.textValue,
        sizeName: item.size?.name,
        sizeValues: item.size?.sizes,
      })),
      userId: user?.id,
      orderComment: formData.comment,
    },
    (res)=>{
      Swal.fire({
        icon: 'success',
        title: '¡Gracias por elegirnos!',
        text: 'Hemos enviado un email para que puedas gestionar el pago de tu pedido',
        confirmButtonText: 'Proceder al pago',
        showCloseButton: false,
        showCancelButton: false,
        allowEnterKey: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
      }).then((swalRes)=>{
        Swal.fire({
          didOpen: () => {
            Swal.showLoading()
          },
          showCloseButton: false,
          allowEnterKey: false,
          allowEscapeKey: false,
          allowOutsideClick: false,
        })
        window.location.href = `/order/${res.data.preferenceId}`
        //handleMercadoPago(res)
      })
    }
    );//usuario de prueba
  };

  return (
    <div className='checkoutScreen_container'>
      <div className='checkoutScreen_products'>
          {
            cartItems.map(item => (
              <CartItem className='checkoutScreen_products_item' key={item.id} product={item} />
            ))
          }
          <div className='checkoutScreen_products_totalAmount'>
            ${totalAmount}
          </div>
      </div>
      <div className='checkoutScreen_addressForm'>
        <div className='addressForm_personalData'>

        </div>
        <Row>
          <Col>
          <FormGroup>
          <Label for="firstName">Nombre</Label>
          <Input
            type="text"
            name="firstName"
            id="firstName"
            value={formData.firstName}
            onChange={handleInputChange}
          />
        </FormGroup>
        
          </Col>
          <Col>
          <FormGroup>
          <Label for="lastName">Apellido</Label>
          <Input
            type="text"
            name="lastName"
            id="lastName"
            value={formData.lastName}
            onChange={handleInputChange}
          />
        </FormGroup>
          </Col>
        </Row>
        <FormGroup>
          <Label for="address">Dirección</Label>
          <Input
            type="text"
            name="address"
            id="address"
            value={formData.address}
            onChange={handleInputChange}
          />
        </FormGroup>
        <FormGroup>
          <Label for="city">Ciudad</Label>
          <Input
            type="text"
            name="city"
            id="city"
            value={formData.city}
            onChange={handleInputChange}
          />
        </FormGroup>
        <FormGroup>
          <Label for="zipCode">Código Postal</Label>
          <Input
            type="text"
            name="zipCode"
            id="zipCode"
            value={formData.zipCode}
            onChange={handleInputChange}
          />
        </FormGroup>
        <FormGroup>
          <Label for="email">Email</Label>
          <Input
            type="text"
            name="email"
            id="email"
            value={formData.email}
            onChange={handleInputChange}
          />
        </FormGroup>
        <FormGroup>
          <Label for="phoneNumber">Telefono</Label>
          <Input
            type="number"
            name="phoneNumber"
            id="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleInputChange}
          />
        </FormGroup>
        <FormGroup>
          <Label for="comment">Comentario</Label>
          <Input
            type="textarea"
            name="comment"
            id="comment"
            value={formData.comment}
            onChange={handleInputChange}
          />
        </FormGroup>
        <Button color="primary" onClick={handleSubmit}>
          Enviar
        </Button>
      </div>
      <div className='checkoutScreen_payment'></div>
    </div>
  )
}

const mapStateToProps = state => ({
  cartItems:state.cart.products,
  totalAmount:state.cart.totalAmount,
  user:state.session.user,
  preferenceId: state.orders.currentPreferenceId
})

const mapDistpatchToProps = dispatch => ({
  saveOrder: (orderData, successCallback, failCallback) => dispatch(OrdersActions.saveOrder(orderData, successCallback, failCallback))
})

export default connect(
    mapStateToProps,
    mapDistpatchToProps
)(CheckoutScreen)