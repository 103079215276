

export const productStatusTypes = {
    ENABLED: 'ENABLED',
    DISABLED: 'DISABLED',
    DELETED: 'DELETED',
}

export const productStatusTypesToName = {
    [productStatusTypes.ENABLED]: "Habilitado",
    [productStatusTypes.DISABLED]: "Deshabilitado"
}

export const sheepProductId = 34;