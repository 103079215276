import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import OrdersActions from "../../store/orders/actions";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { initMercadoPago, Wallet, CardPayment } from "@mercadopago/sdk-react";
import { add } from "lodash";
import { URL_BASE, getUrlImages } from "../../constants/url";

initMercadoPago('TEST-37d0f503-59c3-417a-9372-2bbacc897837');

const UnconnectedOrderDetail = ({ getOrderByPreferenceId }) => {

  const [order, setOrder] = useState(null)

  const params = useParams();

  useEffect(() => {
    console.warn(params);
    setOrder(null);
    const { action } = getOrderByPreferenceId();
    Swal.fire({
      showCloseButton: false,
      allowEnterKey: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      }
    })
    
    action(params.preferenceId).then((res) => {
      const Order = res.data;
      setOrder({
        ...Order,
        address: JSON.parse(Order.address),
        products: JSON.parse(Order.products),

      })
      Swal.close();
      Promise.resolve(Order.mpPreferenceId);
      console.warn("order: ", order);
    });
  }, []);

  const onSubmit = () => {
    return new Promise((resolve, reject) => {
          resolve(order.mpPreferenceId);
    })
  }

  /**
   const onSubmit = async (formData) => {
 // callback called when clicking Wallet Brick
 // this is possible because the Brick is a button
 // at this time of submit, you must create the preference
 const yourRequestBodyHere = {
   items: [
     {
       id: '202809963',
       title: 'Dummy title',
       description: 'Dummy description',
       quantity: 1,
       unit_price: 10,
     },
   ],
   purpose: 'wallet_purchase',
 };
 return new Promise((resolve, reject) => {
   fetch('/create_preference', {
     method: 'POST',
     headers: {
       'Content-Type': 'application/json',
     },
     body: JSON.stringify(yourRequestBodyHere),
   })
     .then((response) => response.json())
     .then((response) => {
       // resolve the promise with the ID of the preference
       resolve(response.preference_id);
     })
     .catch((error) => {
       // handle error response when trying to create preference
       reject();
     });
 });
};
   */

  if(!order){
    return <div></div>
  }

  return <div style={{
    backgroundColor: '#ccc',
    position: 'fixed',
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    justifyContent: 'center',
  }}>
    <div style={{
      position: 'absolute',
      top: 100,
      left: 100,
      bottom: 100,
      right: 100,
      zIndex: 100,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <div className="orderHeader">

      </div>
      <div className="orderBody">
        <h1>Detalle del pedido</h1>
        <label>Detalle del pedido</label>
        <label>Estado: {order.status}</label>
        <label>Fecha: {order.date}</label>
        <label>Productos:</label>
        <ul>
          {order.products.map((product, index) => (
            <li key={index}>

              <p>Producto: {product.name}</p>
              <p>Cantidad: {product.quantity}</p>
              <p>Precio unitario: {product.price}</p>
            </li>
          ))}
        </ul>
        <label>Dirección de envío:</label>
        <p>Calle: {order.address.street}</p>
        <p>Ciudad: {order.address.city}</p>
        <p>Código postal: {order.address.zipCode}</p>
        <label>Comentarios:</label>
        <p>{order.comment}</p>
        <label>Total: {order.totalAmount}</label>
      </div>
    <div className="orderFooter" id="wallet_container">
      <CardPayment
        initialization={{
          amount: order.totalAmount,
        }}
        onReady={(res)=>console.warn('ready', res)} 
        onSubmit={onSubmit}
        onError={(err)=>console.warn(err)} 
        //initialization={{ preferenceId: order.mpPreferenceId, redirectMode: 'blank'}} 
      />
    </div>
    </div>
  </div>;
};

const mapDistpatchToProps = (dispatch) => ({
  getOrderByPreferenceId: () =>
    dispatch(OrdersActions.getOrderByPreferenceId()),
});

const mapStateToProps = (state) => ({});

const OderDetailScreen = connect(
  null,
  mapDistpatchToProps
)(UnconnectedOrderDetail);

export default OderDetailScreen;
