import {handleActions} from "redux-actions";
import { types } from "./actions";

const initialState = {
    createStyleInProgress:false,
    createStyleSuccess:false,
    createStyleFailed:false,
    getStylesInProgress:false,
    getStylesSuccess:false,
    getStylesFailed:false,
    list:[]
}

const reducer = handleActions({
    [types.createStyleInProgress]:state =>({
        ...state,
        createStyleInProgress: true,
        createStyleSuccess: false,
        createStyleFailed: false
    }),
    [types.createStyleSuccess]:(state, action) =>({
        ...state,
        createStyleInProgress: false,
        createStyleSuccess: true,
        createStyleFailed: false
    }),
    [types.createStyleFailed]:(state) =>({
        ...state,
        createStyleInProgress: false,
        createStyleSuccess: false,
        createStyleFailed: true
    }),
    [types.getStylesInProgress]:state =>({
        ...state,
        getStylesInProgress: true,
        getStylesSuccess: false,
        getStylesFailed: false
    }),
    [types.getStylesSuccess]:(state, action) =>({
        ...state,
        getStylesInProgress: false,
        getStylesSuccess: true,
        getStylesFailed: false,
        list: action.payload.data.list
    }),
},initialState)

export default reducer