import {handleActions} from "redux-actions";
import { types } from "./actions";

const initialState = {
    saveOrderInProgress: false,
    saveOrderFailed: false,
    currentPreferenceId: null
}

const reducer = handleActions({
    [types.saveOrderInProgress]:state =>({
        ...state,
        saveOrderInProgress: true,
        saveOrderFailed: false,
    }),
    [types.saveOrderSuccess]:(state, action) =>({
        ...state,
        saveOrderInProgress: false,
        saveOrderFailed: false,
        currentPreferenceId: action.payload.preferenceId
    }),
},initialState)

export default reducer