import React, {useEffect} from 'react'
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import HomeScreen from '../../screens/homeScreen';
import NavBar from '../NavBarComponent';
import ProducsScreen from '../../screens/producsScreen';
import { getValidSession } from '../../store/session/selector';
import { connect } from 'react-redux';
import AppActions from '../../store/application/actions'
import SessionActios from '../../store/session/actions'
import { getAppHasBooted } from '../../store/application/selector'
import LoadScreen from '../../screens/loadingScreen';
import AdminScreen from '../../screens/adminScreen'
import 'sweetalert2/src/sweetalert2.scss'
import RegisterScreen from '../../screens/registerScreen';
import ValidateScreen from '../../screens/validateScreen';
import CheckoutScreen from '../../screens/checkoutScreen';
import OderDetailScreen from '../../screens/orderDetailScreen';

const AplicationRouter = ({
    bootApp,
    validSession,
    appHasBooted,
    tryLoginByToken,
    isAdmin,
}) => {

    useEffect(() => {
      bootApp()
    }, [])

    return (<>
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<NavBar/>}>
                    <Route path='products' element={<ProducsScreen/>}/>
                    <Route path='products/:category' element={<ProducsScreen/>}/>
                    <Route path='checkout' element={<CheckoutScreen/>}/>
                    <Route path='home' element={<HomeScreen/>}/>
                    <Route path='' element={<Navigate to="/home" replace={true}/>}/>
                </Route>
                <Route path='order/:preferenceId' element={<OderDetailScreen/>}/>
                <Route path='/register' element={<RegisterScreen/>}/>
                <Route path='/validate/:token' element={<ValidateScreen/>}/>
                {isAdmin ?
                    <>
                        <Route path='/admin' element={<AdminScreen/>}/>
                    </>
                : null}
                <Route path='*' element={<Navigate to="/home" replace={true}/>}/>
            </Routes>
        </BrowserRouter>
    </>)
}

const mapStateToProps = state => {
    return {
        validSession: getValidSession(state),
        appHasBooted: getAppHasBooted(state),
        isAdmin: state.session.user?.role === 'ADMIN'
    }
}

const mapDistpatchToProps = dispatch => {
    return {
        bootApp:()=>{return dispatch(AppActions.bootApp())},
        tryLoginByToken:()=>{return dispatch(SessionActios.tryLoginByToken())}
    }
}
  
  const connectedApp = connect(
    mapStateToProps,
    mapDistpatchToProps
  )(AplicationRouter)

export default connectedApp