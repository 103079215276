import React, { useEffect, useState } from "react";
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
  Card,
  CardBody,
  Input,
  Button,
} from "reactstrap";
import { URL_BASE } from "../../../../../constants/url";
import "../styles.scss";
import { connect } from "react-redux";
import CartActions from "../../../../../store/cart/actions";
import Swal from "sweetalert2";
import "./styles.scss";
import ProductsActions from '../../../../../store/products/actions'
import { productStatusTypes } from "../../../../../constants";

const UnConnectedEditProductItem = ({ 
  product, 
  addToCart, 
  onEdit, 
  unitTypes,
  sizes,
  categories,
  productsStyles,
  setProductStatus,
  getProducts,
  deleteProduct,
  onClickEdit
}) => {
  if (!product) return;

  const handleEdit = () => {
    onClickEdit(product);
  }

  const firstImage = `url(${URL_BASE}/${product?.images[0]?.path})`
  const isEnabled = product.status == productStatusTypes.ENABLED
  const productStatusAction = isEnabled ? "Deshabilitar" : "Habilitar"
  const productStatusText = isEnabled ? "Esto hara que los usuarios dejen de ver el producto en la lista" : "Esto hará que los usuarios vuelvan a ver el producto en la lista"

  const handleToggleStatus = () => {
    Swal.fire({
      title: `¿Estás seguro de ${productStatusAction} ${product.name}?`,
      text: productStatusText,
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Estoy Seguro",
    }).then((result) => {
      if(result.isConfirmed){
        setProductStatus(product.id, isEnabled ? productStatusTypes.DISABLED : productStatusTypes.ENABLED)
        .then(res=>{
          if(res.data){
            getProducts();
            Swal.close();
            Swal.fire({
              icon: 'success',
              title: isEnabled ? 'Deshabilitado' : 'Habilitado',
              text: 'El estado del producto ha sido cambiado exitosamente',
              timer: 2000,
              timerProgressBar: true,
            })
          }else{
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Hubo un error al cambiar el estado del producto, si el error persiste contacte con soporte',
              confirmButtonText: 'Entendido',
            })
          }
        })
        Swal.fire({
          title: isEnabled ? 'Deshabilitando Producto' : 'Habilitando Producto',
          text: 'Espere un momento',
          showConfirmButton: false,
        })
        Swal.showLoading();
      }
    })
  }

  const handleDeleteProduct = () => {
    Swal.fire({
      title: `¿Está seguro de eliminar ${product.name}?`,
      text: "Esta accion no puede deshacerse",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Si, Estoy Seguro",
      icon: 'warning',
    }).then((result) => {
      if(result.isConfirmed){
        deleteProduct(product.id).then(res=>{
          getProducts();
          Swal.close();
          if(res.data){
            Swal.fire({
              icon: 'success',
              title: 'Eliminado',
              text: 'El producto ha sido eliminado exitosamente',
              timer: 2000,
              timerProgressBar: true,
            })
          }else{
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Hubo un error al eliminar el producto, si el error persiste contacte con soporte',
              confirmButtonText: 'Entendido',
              showCloseButton: true
            })
          }
        })
        Swal.fire({
          title: 'Eliminando Producto',
          text: 'Espere un momento',
          showConfirmButton: false,
        })
        Swal.showLoading();
      }
    })

  }

  return (
    <div className="editProductItem_container">
      {product.inOffer ? 
        <div className="editProductItem_inOffer">
          ¡Oferta!
        </div>
      :null}
      {product.status == productStatusTypes.DISABLED ?
        <div className="editProductItem_disabled">
          Deshabilitado
        </div>
      : null}
      {product.status == productStatusTypes.DELETED ?
        <div className="editProductItem_deleted">
          Eliminando
        </div>
      : null}
        <div className="editProductItem_toggleStatusBtn" onClick={handleToggleStatus}>
          {productStatusAction}
        </div>
        <div
          style={{
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "contain",
            width: "120px",
            height: "120px",
            backgroundImage: firstImage,
            backgroundColor: "white",
          }}
        />
        <div className="editProductItem_body">
          <div className="editProductItem_header">
            <label className="editProductItem_name">{product.name}</label>
              <label className="editProductItem_price">
                ${product.pricePerUnitType} x {product.unitType.shortName}
              </label>
          </div>
        </div>
        <div className="editProductItem_actions">
          <div onClick={handleEdit}>Editar</div>
          <div></div>
          {product.id != 34 ?
            <div onClick={handleDeleteProduct}>Eliminar</div>
          : <div/>}
        </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  unitTypes:state.units.list,
  sizes:state.sizes.list,
  categories:state.categories.list,
  productsStyles:state.productsStyles.list,
});

const mapDistpatchToProps = (dispatch) => ({
  setProductStatus: (id, status)=>dispatch(ProductsActions.setStatus(id, status)),
  getProducts: ()=>dispatch(ProductsActions.loadAdminProducts()),
  deleteProduct: (id)=>dispatch(ProductsActions.deleteProduct(id)),
});

const EditProductItem = connect(
  mapStateToProps,
  mapDistpatchToProps
)(UnConnectedEditProductItem);

export default EditProductItem;
