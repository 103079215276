import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import SessionActions from '../../store/session/actions'
import { Container, Form, FormGroup, Label, Input, Button, Toast, ToastHeader, ToastBody } from 'reactstrap';
import './styles.scss';
import { Link } from 'react-router-dom';

const ErrorToast = ({error, toggle}) => {

    return  <div className='registerForm_form_toast'>
              {error}
            </div>
}

const UnconnectedRegisterScreen = ({ register }) => {
    const [formData, setFormData] = useState({
      email: '',
      password: '',
      firstName: '',
      lastName: '',
      birthdate: '',
      dni: '',
    });

    const [passwordErrors, setPasswordErrors] = useState({})
    const [isOpenPasswordToast, setIsOpenPasswordToast] = useState(false)
    const [errors, setErrors] = useState({});
  
    // Objeto de validación para los campos del formulario
    const validationRules = {
      email: {
        required: true,
        pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, // Expresión regular para validar el formato del correo electrónico
      },
      password: {
        required: true,
        pattern: /^(?=.*[\W_])(?=.*\d)(?=.*[A-Z])(?=.*[a-z]).{8,}$/,
      },
      firstName: {
        required: true,
        pattern: /^[a-zA-Z]+$/, // Expresión regular para validar el formato del nombre
      },
      lastName: {
        required: true,
        pattern: /^[a-zA-Z]+$/, // Expresión regular para validar el formato del apellido
      },
      birthdate: {
        required: true,
      },
      dni: {
        required: true,
      },
    };

    const resetForm = () => {
        setFormData({
          email: '',
          password: '',
          firstName: '',
          lastName: '',
          birthdate: '',
          dni: '',
        });
    }

    const validatePassword = (password) => {
        const passwordValidations = {
          length: /[^\s]{6}/, // Al menos 6 caracteres
          number: /\d/, // Un número
          uppercase: /[A-Z]/, // Una mayúscula
          lowercase: /[a-z]/, // Una minúscula
        };
      
        setPasswordErrors({
            length: passwordValidations.length.test(password),
            number: passwordValidations.number.test(password),
            uppercase: passwordValidations.uppercase.test(password),
            lowercase: passwordValidations.lowercase.test(password),
          })
      }

    const passwordValid = () => {
      if(formData.password.length == 0) return true;
      return (passwordErrors.length && passwordErrors.number && passwordErrors.uppercase && passwordErrors.lowercase);
    }
  
    // Función para validar el formulario
    const validateForm = (fieldName) => {
      let isValid = true;
      let newErrors = {};
  
    if (validationRules.hasOwnProperty(fieldName)) {
        const rules = validationRules[fieldName];
        const value = formData[fieldName];

        if (rules.required && value.length) {
            newErrors[fieldName] = 'Este campo es requerido';
            isValid = false;
        }else{
            newErrors[fieldName] = null;
            isValid = true;
        }

        if (rules.pattern && !rules.pattern.test(value)) {
            newErrors[fieldName] = 'Formato inválido';
            isValid = false;
        }else{
            newErrors[fieldName] = null;
            isValid = true;
        }
    }
  
      setErrors(prevErrors=>({
        ...prevErrors,
        ...newErrors
        }));
      return isValid;
    };
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      if(name === 'password'){
        validatePassword(value);
      }
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    };

    const handleBlur = (e) => {
        if(e.target.name === "password"){
            closePasswordToast()
        }
        handleChange(e);
        validateForm(e.target.name);
    }
  
    const handleSubmit = () => {
      if (validateForm()) {
        register(formData, resetForm);
      }
    };

    const openPasswordToast = (e) => {
        handleChange(e);
        setIsOpenPasswordToast(true); 
    }

    const closePasswordToast = () => {
        setIsOpenPasswordToast(false);
    }
    
    return (
        <div className='registerScreen'>
            
            <Container className="registerForm_container">
                <div className='registerForm_header'>
                    <p className='registerForm_header_title'>HDL</p>
                    <p className='registerForm_header_subtitle'>Gracias por elegirnos.</p>
                </div>
                <div className='registerForm_form' onSubmit={handleSubmit}>
                    <div>
                    <i className='fa fa-user-circle fa-5x' />
                    <p>
                        Por favor, introduzca sus datos para registrarse.
                    </p>
                    <p>
                        Una vez registrado, se le enviara un correo para confirmar el registro
                    </p>
                    </div>
                    <FormGroup className='registerForm_form_fullName'>
                        <FormGroup className='registerForm_inputSection'>
                            <Label for="firstName">Nombre</Label>
                            <Input
                            type="text"
                            name="firstName"
                            id="firstName"
                            value={formData.firstName}
                            onChange={handleChange}
                            invalid={!!errors.firstName}
                            onBlur={handleBlur}
                            />
                            {errors.firstName && <ErrorToast error={errors.firstName}></ErrorToast>}
                        </FormGroup>
                        <div></div>
                        <FormGroup className='registerForm_inputSection'>
                            <Label for="lastName">Apellido</Label>
                            <Input
                            type="text"
                            name="lastName"
                            id="lastName"
                            value={formData.lastName}
                            onChange={handleChange}
                            invalid={!!errors.lastName}
                            onBlur={handleBlur}
                            />
                            {errors.lastName && <ErrorToast error={errors.lastName}></ErrorToast>}
                        </FormGroup>
                    </FormGroup>
                    <FormGroup className='registerForm_inputSection'>
                            <Label for="birthdate">Fecha de nacimiento</Label>
                            <Input
                            type="date"
                            name="birthdate"
                            id="birthdate"
                            value={formData.birthdate}
                            onChange={handleChange}
                            invalid={!!errors.birthdate}
                            onBlur={handleBlur}
                            />
                            {errors.birthdate && <div>{errors.birthdate}</div>}
                    </FormGroup>
                    <FormGroup className='registerForm_inputSection'>
                        <Label for="email">Email</Label>
                        <Input
                        type="email"
                        name="email"
                        id="email"
                        value={formData.email}
                        onChange={handleChange}
                        invalid={!!errors.email}
                        onBlur={handleBlur}
                        />
                        {errors.email && <ErrorToast error={errors.email}></ErrorToast>}
                    </FormGroup>
                    <FormGroup className='registerForm_form_password registerForm_inputSection'>
                        <Label for="password">Contraseña</Label>
                        <Input
                        type="password"
                        name="password"
                        id="password"
                        value={formData.password}
                        onChange={handleChange}
                        invalid={!passwordValid()}
                        onFocus={openPasswordToast}
                        onBlur={handleBlur}
                        />
                        <Toast className='registerForm_form_password_toast' transition={200} isOpen={isOpenPasswordToast} onClose={closePasswordToast}>
                            <ToastBody>
                                <p className='registerForm_form_password_toast_item'><i style={{color: passwordErrors.length ? '#009933' : '#cc0000'}} className='fa fa-check-circle fa-2x'></i>Al menos 6 caracteres</p>
                                <p className='registerForm_form_password_toast_item'><i style={{color: passwordErrors.number ? '#009933' : '#cc0000'}} className='fa fa-check-circle fa-2x'></i>Al menos un número</p>
                                <p className='registerForm_form_password_toast_item'><i style={{color: passwordErrors.uppercase ? '#009933' : '#cc0000'}} className='fa fa-check-circle fa-2x'></i>Al menos una mayuscula</p>
                                <p className='registerForm_form_password_toast_item'><i style={{color: passwordErrors.lowercase ? '#009933' : '#cc0000'}} className='fa fa-check-circle fa-2x'></i>Al menos una minuscula</p>
                            </ToastBody>
                        </Toast>
                    </FormGroup>
                    <div className='registerForm_button'>
                        <Button color="primary" onClick={handleSubmit}>
                            Registrarse
                        </Button>
                        <Link className='btn btn-primary' to={'/*'} replace>
                            Volver
                        </Link>
                    </div>
                </div>
            </Container>
        </div>
    );
  };

const mapDistpatchToProps = (dispatch) => ({
    register: (data, successCallback, failCallback) => dispatch(SessionActions.register(data, successCallback, failCallback)),
    validateEmailExist: () => dispatch(SessionActions.validateEmailExist()),
})

const mapStateToProps = (state) => ({
  
})

const RegisterScreen = connect(
mapStateToProps,
mapDistpatchToProps
)(UnconnectedRegisterScreen)

export default RegisterScreen