import {handleActions} from "redux-actions";
import { types } from "./actions";

const initialState = {
    createSizeInProgress:false,
    createSizeSuccess:false,
    createSizeFailed:false,
    getSizeInProgress:false,
    getSizeSuccess:false,
    getSizeFailed:false,
    list:[]
}

const reducer = handleActions({
    [types.createSizeInProgress]:state =>({
        ...state,
        createSizeInProgress: true,
        createSizeSuccess: false,
        createSizeFailed: false
    }),
    [types.createSizeSuccess]:(state, action) =>({
        ...state,
        createSizeInProgress: false,
        createSizeSuccess: true,
        createSizeFailed: false
    }),
    [types.createSizeFailed]:(state) =>({
        ...state,
        createSizeInProgress: false,
        createSizeSuccess: false,
        createSizeFailed: true
    }),
    [types.getSizesInProgress]:state =>({
        ...state,
        getSizeInProgress: true,
        getSizeSuccess: false,
        getSizeFailed: false
    }),
    [types.getSizesSuccess]:(state, action) =>({
        ...state,
        getSizeInProgress: false,
        getSizeSuccess: true,
        getSizeFailed: false,
        list: action.payload.data.list
    }),
},initialState)

export default reducer