import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import SessionActions from '../../store/session/actions'
import { useParams } from 'react-router-dom'
import Swal from 'sweetalert2'

const ValidateScreen = ({
  validateAccount
}) => {

    const params = useParams();

  useEffect(() => {

    const { action } = validateAccount()

    action(params.token).then(res=>{
        console.warn("response: ",res);

        const { data } = res

        switch(data.type){
            case "success":
                Swal.fire({
                    title: 'Valicion exitosa',
                    icon: 'success',
                    html:
                        '<b><p>Inicie sesion su email y contraseña.</p></b>' +
                        '<p>Gracias por elegirnos.</p>' +
                        '<p>Ya puede cerrar esta ventana.</p>',
                    showConfirmButton: false,
                    showCloseButton: false,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                  })
            break;
            case "info":
                Swal.fire({
                    title: '',
                    icon: 'info',
                    html:`
                        <b>${data.message}</b>
                        <p>Puede cerrar esta ventana</p>
                        `,
                    showConfirmButton: false,
                    showCloseButton: false,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                  })
            break;
            case "error":
                Swal.fire({
                    title: 'Ups!',
                    icon: 'error',
                    html:
                        `<b>${res.data.error}</b>`,
                        //'<a href="http://haciendadellitoral.com">Volver</a> ',
                    showConfirmButton: false,
                    showCloseButton: false,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                })
            break;
            default:
                Swal.fire({
                    title: 'Ups!',
                    icon: 'error',
                    html:
                        `<b>Algo salio mal</b>` +
                        '<a href="http://haciendadellitoral.com">Volver</a> ',
                    showConfirmButton: false,
                    showCloseButton: false,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                  })
            break;
        }
    })
  }, [])
  

  return (
    <div>
    </div>
  )
}

const mapDistpatchToProps = (dispatch) => ({
    validateAccount: ()=>dispatch(SessionActions.validateAccount())
})

const mapStateToProps = (state) => ({

})

const ConnectedValidateScreen = connect(
null,
mapDistpatchToProps
)(ValidateScreen)

export default ConnectedValidateScreen