import {handleActions} from "redux-actions";
import { types } from "./actions";

const initialState = {
    createCategoryInProgress:false,
    createCategorySuccess:false,
    createCategoryFailed:false,
    getCategoryInProgress:false,
    getCategorySuccess:false,
    getCategoryFailed:false,
    list:[]
}

const reducer = handleActions({
    [types.createCategoryInProgress]:state =>({
        ...state,
        createCategoryInProgress: true,
        createCategorySuccess: false,
        createCategoryFailed: false
    }),
    [types.createCategorySuccess]:(state, action) =>({
        ...state,
        createCategoryInProgress: false,
        createCategorySuccess: true,
        createCategoryFailed: false
    }),
    [types.createCategoryFailed]:(state) =>({
        ...state,
        createCategoryInProgress: false,
        createCategorySuccess: false,
        createCategoryFailed: true
    }),
    [types.getCategoriesInProgress]:state =>({
        ...state,
        getCategoryInProgress: true,
        getCategorySuccess: false,
        getCategoryFailed: false
    }),
    [types.getCategoriesSuccess]:(state, action) =>({
        ...state,
        getCategoryInProgress: false,
        getCategorySuccess: true,
        getCategoryFailed: false,
        list: action.payload.data.list
    }),
},initialState)

export default reducer