import React from "react"; 

import './styles.scss'

const STPagination = ({totalPages, changePage, activePage}) => {
    let pages = new Array;

    for (let page = 1; page <= totalPages; page++) {
      let active = activePage === page ? 'active' : ''    
      pages.push(
        <div className={`STPagination_item ${active}`} onClick={()=>changePage(page)}>
          {page}
        </div>
      )
      
    }

    return <div className="STPagination_container">{pages.map(page=>page)}</div>

}


export default STPagination;