import React, { useEffect, useState } from 'react';
import { URL_BASE } from '../../../constants/url';
import '../styles.scss'
import { connect } from 'react-redux';
import CartActions from '../../../store/cart/actions'

import './styles.scss';

const UnConnectedCartItem = ({
  className='', 
  product, 
  remove, 
  update,
  sizes,
  unitTypes,
  productsStyles,
  categories,
}) => {
    if(!product) return;
    
    const step = product.unitType.steps;

    const handleUpdate = (value) => {
        update({
            ...product,
            quantity:value
        })
    }

    const handleIncrementQuantity = () => {
      handleUpdate((Number(product.quantity))+Number(step))
    }
  
    const handleDecrementQuantity = () => {
      const newQuantity = Number(product.quantity) - Number(step);
      if(newQuantity < step) return;
      handleUpdate(newQuantity)
    }

  const items = product.images?.map((image, index)=>({
    src: `${URL_BASE}/${image.path}`,
    key: index
  }))

  return (
    <div className={`cartItem ${className}`}>
      <div className='cartItem_container'>
        <div className='cartItem_image' style={{
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'contain',
          width: '100%',
          height : '110px',
          backgroundImage: `url(${items[0]?.src})`
        }}>
        </div>
        {product.inOffer ? 
          <div className="cartItem_inOffer">
            ¡Oferta!
          </div>
        :null}
        <div className='cartItem_data'>
          <div className='cartItem_data_header'>
            <label className='cartItem_data_header_name'> 
              {product.name}
            </label>
            <div className="cartItem_price">
              ${product.pricePerUnitType} x {product.unitType.shortName}
            </div>
          </div>
          {product.size || product.customSizes ?
            <div className='cartItem_data_fixed'>
              <div className='cartItem_data_fixed_section'>
                {product.size?.name ?
                  <div className='cartItem_data_fixed_section_item'>
                    <p>Tamaño:</p>
                    <div>{product.size.name}</div>
                  </div>
                : null}
                <div className='cartItem_data_fixed_section_item'>
                  <p>Peso:</p>
                  <div>{product.quantity}{product.unitType.shortName}</div>
                </div>
              </div>
              {product.cuts || product.style ?
                <div className='cartItem_data_fixed_section'>
                  {product.cuts ?
                    <div className='cartItem_data_fixed_section_item'>
                      <p>Cortes:</p>
                      <div>{product.cuts}</div>
                    </div>
                  : null}
                  {product.style ?
                    <div className='cartItem_data_fixed_section_item'>
                      <p>Estilo:</p>
                      <div>{product.style.name}</div>
                    </div>
                  : null}
                </div>
              :null}
            </div>
          : <div className='cartItem_kgInput'>
              <i className='fa fa-minus cartItem_minus' onClick={handleDecrementQuantity}/>
              <p className='cartItem_input'>{product.quantity}{product.unitType.shortName}</p>
              <i className='fa fa-plus cartItem_plus' onClick={handleIncrementQuantity}/>
            </div>     
          }
          {product.style ?
            <p className='cartItem_data_style_text'>{product.style.textValue}</p>
          :null}
          <div className='cartItem_data_footer'>
            <div className='cartItem_data_footer_total'> 
             Total: <strong>${product.totalAmount}</strong>
            </div>
            <div className='cartItem_removeBtn' onClick={()=>remove(product.productId)}>
              Eliminar
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

/*
 <div className="productItem_body">
          {product.sizesIds?.length > 0 ? (
            <>
              <div className="productItem_selector_container">
                <div className="productItem_selector">
                  {inCart ? 
                    <div className="productItem_selector_inCart"/>
                  :null}
                  <label>Tamaño</label>
                  <select disabled={inCart} onChange={handleSelectSize}>
                    <option>Seleccione..</option>
                    {product.sizesIds.split(",").map((sizeId) => {
                      const size = sizes.find((size) => size.id == sizeId);
                      return (
                        <option selected={selectedSize?.id == size.id} value={size?.id} id={product.name+size.id}>
                          {size?.name}
                        </option>
                      ) 
                    })}
                  </select>
                </div>
              </div>
              <div className="productItem_selector_container">
                <div className="productItem_selector">
                  <label>Peso</label>
                  <select>
                    <option>Seleccione..</option>
                    {selectedSize?.sizes.split(",").map((size) => {
                      const unitType = unitTypes.find((unit) => unit.id == selectedSize.unitTypeId); 
                      return (
                        <option value={size} id={selectedSize.id+size}>
                          {size} {unitType.shortName}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              {product.cuts ? (
                <div className="productItem_selector_container">
                <div className="productItem_selector">
                  <label>Cortes</label>
                  <select onChange={handleSelectCuts} value={selectedCuts}>
                    <option>Ninguno</option>
                    {product.cuts.split(",").map((cut) => {
                      return (
                        <option value={cut} id={product.name+"cut"+cut} selected={selectedCuts == cut}>
                          {cut}
                        </option>
                      )
                    })}
                  </select>
                  </div>
                </div>
              ) : null}
            </>
          ) : product.customSizes ? (
            <div className="productItem_selector_container">
              <div className="productItem_selector">
                <label>Peso</label>
                <select>
                  {product.customSizes.split(",").map((size) => {
                    return (
                      <option value={size} id={"customSize"+size}>
                        {size} {product.unitType.shortName}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          ) : (
              
          <div className="productItem_kgInput_container">
            {inCart ? 
              <div className="productItem_kgInput_inCart"/>
            :null}
            <label>Peso</label>
            <div className="productItem_kgInput_input">
              <i className="fa fa-minus" onClick={handleDecrementquantity} />
              <div className="productItem_kgInput_input_value">
                {quantity}
              </div>
              <i className="fa fa-plus" onClick={handleIncrementquantity} />
            </div>
          </div>
            )
          }
          {!inCart ? (
            <div className={`productItem_addToCart ${hasquantity ? 'canAdd' : ''} `} onClick={handleAddToCart}>
              Agregar al carrito
            </div>
          ) : (
            <div className="productItem_inCart" onClick={openCart}>
              Ver en el carrito
            </div>
          )}
          {product.stylesIds?.length > 0 ? (
          <>
            <div className="productItem_selector_container">
              <div className="productItem_selector">
                <label>Estilo</label>
                <select onChange={handleSelectStyle}>
                  <option>Seleccione..</option>
                  {product.stylesIds.split(",").map((styleId) => {
                    const style = productsStyles.find((style) => style.id == styleId);
                    return (
                      <option value={style.id} id={style.id+style.name} selected={selectedStyle?.id == style.id}>
                        {style.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              {selectedStyle?.textValue ? (
                <p>{selectedStyle?.textValue}</p>
              ) : null}
              </div>
          </>
        ) : null}
        </div>
*/

const mapStateToProps = (state) => ({
  cartItems:state.cart.products,
  unitTypes:state.units.list,
  sizes:state.sizes.list,
  categories:state.categories.list,
  productsStyles:state.productsStyles.list,
})

const mapDistpatchToProps = (dispatch) => ({
    incremnt: ()=>{},
    decrement: ()=>{},
    update: (newData)=>{dispatch(CartActions.updateItem(newData))},
    remove: (id)=>dispatch(CartActions.removerFromCart(id)),
    clear: ()=>dispatch(CartActions.clearCart)
})

const CartItem = connect(
    mapStateToProps,
    mapDistpatchToProps
)(UnConnectedCartItem)

export default CartItem;