import { configureStore } from '@reduxjs/toolkit'
import logger from 'redux-logger'
import thunk from 'redux-thunk'
import { persistStore, persistReducer, autoRehydrate } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import rootReducer from './reducer'
import { API } from './Api';
import { applyMiddleware, createStore, compose } from 'redux';
import reducer from './reducer';
//import { applyMiddleware } from 'redux';
const Api = API;
const thunkMiddleware = thunk.withExtraArgument(Api);
const enhancers = []

const middleware = [thunkMiddleware, logger];

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers
);

const persistConfig = {
  key: 'authType',
  storage: storage
};

const pReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(pReducer, {}, composedEnhancers);

const persistor = persistStore(store);

export { persistor, store };