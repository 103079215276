import {handleActions} from "redux-actions";
import { types } from "./actions";

const initialState = {
    title: null,
    messaje: null,
    open: false,
}

const reducer = handleActions({
    [types.showpopupMessage]: (state, action) => {
        
        return{
            ...state,
            title: action.payload.title,
            message: action.payload.message
        }
    },
    [types.closepopup]: (state, action) => ({
        ...state,
        title: null,
        messaje: null,
        open: false,
    })
},initialState)

export default reducer