import React from 'react'
import { connect } from 'react-redux'
import { popupActions } from '../../store/popup/actions'

import './styles.scss'

const uncconnectedPopup = ({title, message, closePopup}) => {

    const shouldOpenClass = title != null && message != null ? 'popup_open' : '' 

  return (
    <div className={`popup ${shouldOpenClass}`}>
        <div className='popup_container'>
            <div className='popup_title'>
                <strong>{title}</strong>
                <i className='fa fa-times popup_closebtn' onClick={closePopup}/>
            </div> 
            <div className='popup_message'>
                {message}
            </div>
        </div>
    </div>
  )
}

const mapStateToProps = (state) => {
    return {
        title: state.popup.title,
        message: state.popup.message,
        open: state.popup.open,
    }
}

const mapDistpatchToProps = (dispatch) => {
    return {
        closePopup:()=>dispatch(popupActions.closePopup())
    }
}

const Popup = connect(
mapStateToProps,
mapDistpatchToProps
)(uncconnectedPopup)

export default Popup