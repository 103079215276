import React, {useEffect, useState} from 'react'
import { connect } from 'react-redux'
import { Link, Navigate } from 'react-router-dom'
import { FiEye, FiEyeOff, FiUser } from 'react-icons/fi'
import { AiOutlineLoading3Quarters } from 'react-icons/ai'
import { RiLockPasswordLine } from 'react-icons/ri'
import sessionActions from '../../store/session/actions'


import './styles.scss'
import { Modal, ModalBody } from 'reactstrap'
import LoadingIcon from '../loadingIcon'

const LoginModal = ({
  logIn,
  closeModal,
  isOpen,
  loginInProgress,
  loginSuccess
}) => { 
  
  const [userName, setUserName] = useState("")
  const [password, setPassword] = useState("")
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = e => {
    e.preventDefault()
    e.stopPropagation()
    logIn(userName,password)
  }

  const resetForm = () => {
    setUserName("")
    setPassword("")
  }

  const handleKeyDown = e => {
    if(e.keyCode === 27){
      closeModal()
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown, false);
  })
  useEffect(() => {

    if(loginSuccess){
      resetForm()
      closeModal();
    }
  }, [loginInProgress])
  

  return (
    <Modal className='login_Container' isOpen={isOpen}>
      <ModalBody style={{height:445}}>
      {loginInProgress ?
        <div className='login_loadingIcon'>
          <LoadingIcon height={50} width={50} show/>
        </div>
      : null}
      <i className="fa fa-times login_CloseBtn" onClick={closeModal} />
        <form className='login_Container' onSubmit={handleSubmit}>
          <div className='login_Panel'>
            <div className='login_Panel_Form'>
                <div className='login_Panel_Form_Header'>
                  <h3>Ingresa con tu email y contraseña</h3>
                </div>
                <div className='login_Panel_Form_Content'>
                  <div className='input-group'>
                    <label>Email</label>
                    <div className='input'>
                      <FiUser style={{height:35, width:35}}></FiUser>
                      <input type="mail" value={userName} onChange={e=>{setUserName(e.target.value)}} />
                    </div>
                  </div> 
                  <div className='input-group'>
                    <label>Contraseña</label>
                    <div className='input'>
                      {password.length > 0 ?
                        showPassword ?
                          <FiEye style={{height:35, width:35}} onClick={()=>setShowPassword(false)}></FiEye>
                        :
                          <FiEyeOff style={{height:35, width:35}} onClick={()=>setShowPassword(true)}></FiEyeOff>
                        : <RiLockPasswordLine style={{height:35, width:35}}></RiLockPasswordLine>
                      }
                      <input type={showPassword ? 'text' : 'password'} value={password} onChange={e=>{setPassword(e.target.value)}}/>
                    </div>
                  </div>
                  <div className='input-group_remember-forgot'>
                    <div className='remember'>
                      <input id='rememberSession' type="checkbox"/>
                      <label htmlFor='rememberSession'>Recordar por 7 días</label>
                    </div>
                    <div className='forgot'>
                      <Link to="/forgot-password">¿Olvidaste tu contraseña?</Link>
                    </div>
                  </div>
                  <div className='input-group_submit_login'>
                    <button type='submit'>Ingresar</button>
                  </div>
                </div>
            </div>
          </div>
        </form>
          <Link onClick={closeModal} className='registerLink' to="/register" replace={true} >o Registrarse</Link>
      </ModalBody>
    </Modal>
  )
}

const mapStateToProps = state => {
  return {
    loginFailed: state.session.loginFailed,
    loginInProgress: state.session.loginInProgress,
    loginSuccess: state.session.loginSuccess
  }
}

const mapDistpatchToProps = dispatch => {
  return{
    logIn:(userName, password)=>{return dispatch(sessionActions.logIn(userName,password))}
  }
}

const connectedLogin = connect(
  mapStateToProps,
  mapDistpatchToProps
)(LoginModal)

export default connectedLogin